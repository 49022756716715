import React from 'react'
import Rollbar from 'rollbar'

interface Config {
  gqlBaseUrl: string
  gfBaseUrl: string
  suppliersUrl: string
  rollbar: Rollbar.Configuration
}

const ConfigContext = React.createContext<Config | undefined>(undefined)

export default ConfigContext
