import { ReactNode } from 'react'
import { Disclosure } from '@headlessui/react'
import { ChevronRightIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import Time from '../modules/Time'

import Card from './Card'
import { TableWrapper } from './Table'

const ReportingComparisonDataCard = ({
  dataChildren,
  comparisonDataChildren,
  comparisonDateRange,
}: {
  dataChildren: ReactNode
  comparisonDataChildren: ReactNode
  comparisonDateRange: { from: string; to: string } | undefined
}) => (
  <Card title="Data">
    <Card.Section>
      <TableWrapper>{dataChildren}</TableWrapper>
    </Card.Section>
    {comparisonDateRange && (
      <Card.Section>
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="inline-flex items-center">
                <span className="text-sm text-gray-700">
                  Comparison Data (
                  {Time.toDateRangeString(
                    comparisonDateRange.from,
                    comparisonDateRange.to,
                    Time.formatDateTime
                  )}
                  )
                </span>
                <ChevronRightIcon
                  className={classNames('inline-block w-6 h-6 text-gray-700 ml-0.5', {
                    'transform rotate-90': open,
                  })}
                />
              </Disclosure.Button>
              <Disclosure.Panel className="mt-4">
                <TableWrapper>{comparisonDataChildren}</TableWrapper>
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </Card.Section>
    )}
  </Card>
)

export default ReportingComparisonDataCard
