import { ArrowCircleDownIcon, ArrowCircleUpIcon, MinusCircleIcon } from '@heroicons/react/solid'
import classNames from 'classnames'

import { ComparisonProps } from '../../types'

import MoneyContext from '../modules/Money'

const Comparison = ({
  value,
  comparisonValue,
  className,
  displayType = 'percent',
  downIsGood = false,
  textColorGood = 'text-green-600',
  textColorBad = 'text-red-600',
  textColorNeutral = 'text-gray-600',
  toFixed = 1,
}: ComparisonProps) => {
  const valueNum = typeof value === 'number' ? value : MoneyContext.toDecimal(value)
  const comparisonValueNum =
    typeof comparisonValue === 'number' ? comparisonValue : MoneyContext.toDecimal(comparisonValue)
  const valueDiff = valueNum - comparisonValueNum
  const percentDiff = (valueDiff / comparisonValueNum) * 100.0
  const valueDiffString = valueDiff.toFixed(toFixed)
  const percentDiffString = `${percentDiff.toFixed(toFixed)}%`
  const { textColor, Icon } =
    valueDiff > 0.0
      ? { textColor: downIsGood ? textColorBad : textColorGood, Icon: ArrowCircleUpIcon }
      : valueDiff < 0.0
        ? { textColor: downIsGood ? textColorGood : textColorBad, Icon: ArrowCircleDownIcon }
        : { textColor: textColorNeutral, Icon: MinusCircleIcon }
  return (
    <div
      className={classNames(
        `inline-flex items-baseline text-xs lg:text-sm ${textColor}`,
        className
      )}
    >
      <Icon className="w-5 h-5 self-center mr-1" aria-hidden="true" />
      <span className="sr-only">{valueDiff > 0.0 ? 'Increased' : 'Decreased'} by</span>
      <span>
        {displayType === 'percent'
          ? percentDiffString
          : displayType === 'money'
            ? MoneyContext.format(MoneyContext.fromDecimal(valueDiff, 'USD'))
            : valueDiffString}
      </span>
    </div>
  )
}

export default Comparison
