import Money from '@/gf/modules/Money'

import { Product, UpdateProductForm, UpdateProductFormPayload } from '../../types'

const fromProduct = (product: Product): UpdateProductForm => ({
  id: product.id,
  name: product.name,
  mpn: product.mpn,
  altMpn: product.altMpn || '',
  sku: product.sku || '',
  shortDescription: product.shortDescription || '',
  longDescription: product.longDescription || '',
  availability: product.availability || 'in_stock',
  leadTime: product.leadTime || '',
  leadTimeDate: product.leadTimeDate || '',
  isOem: product.isOem,
  listPrice: product.listPrice,
  shippingCost: product.shippingCost,
  brandId: product.brand && product.brand.id,
  categoryId: product.category && product.category.id,
})

const toPayload = (form: UpdateProductForm): UpdateProductFormPayload => {
  const listPrice = form.listPrice && Money.toPayload(form.listPrice)
  const shippingCost = form.shippingCost && Money.toPayload(form.shippingCost)

  // when form.image is:
  // undefined | sends null, meaning "don't change image"
  // null      | sends "remove", meaning "remove current image"
  // else      | sends the image URL
  const imageUrl = form.image !== undefined ? form.image?.url || 'remove' : null

  return { ...form, listPrice, shippingCost, imageUrl }
}

export default { fromProduct, toPayload }
