import {
  EventDisplayData,
  EventType,
  RfqEvent,
  RfqEventNotificationSubscriptionData,
  RfqEventPayload,
  RfqEventVendorQuoteFileUploadedData,
  RfqEventViewedReceipt,
} from '@/types'
import {
  ChatIcon,
  CheckIcon,
  ClipboardCheckIcon,
  DocumentAddIcon,
  EyeIcon,
  InboxInIcon,
  LockClosedIcon,
  OfficeBuildingIcon,
  PlusIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  UserAddIcon,
  UserRemoveIcon,
  XIcon,
} from '@heroicons/react/solid'
import A from '../components/A'
import DoubleCheck from '../components/DoubleCheck'
import { Config } from '../contexts/ConfigContext'
import RequestForQuote from './RequestForQuote'
import Time from './Time'
import User from './User'

const getEventDisplayData =
  (showStoreNames: boolean, config: Config) =>
  (event: RfqEvent): EventDisplayData => {
    const gearflowAdmin = event.user && User.isAdmin(event.user) ? ' (Gearflow Admin)' : ''
    const byEventUser = event.user ? (
      <>
        by{' '}
        <span className="text-gray-900">
          {event.user.name}
          {gearflowAdmin}
        </span>
      </>
    ) : null
    function getNoteIcon() {
      if (event.privacy === 'private') {
        return LockClosedIcon
      }
      if (event.privacy === 'protected') {
        if (event.user && User.isAdmin(event.user)) return ShieldCheckIcon
        if (event.store) return ShieldCheckIcon
        return LockClosedIcon
      }
      if (event.privacy === 'supplier_protected') {
        return ShieldCheckIcon
      }
      if (event.privacy === 'all_suppliers') {
        return ShieldCheckIcon
      }
      return ChatIcon
    }

    const defaultData: EventDisplayData = {
      id: event.id,
      type: EventType.RFQ,
      sourceLink: `${window.location.origin}/rfqs/${event.requestForQuoteId}#${event.id}`,
      sourceLinkMessage:
        event.type === 'note'
          ? `Comment left on Request ${RequestForQuote.shortenId(event.requestForQuoteId)}`
          : `Event on Request ${RequestForQuote.shortenId(event.requestForQuoteId)}`,
      datetime: event.insertedAt,
      content: <>Internal error - event type {event.type} not found</>,
      icon: QuestionMarkCircleIcon,
      iconBackground: 'bg-gray-400',
    }

    switch (event.type) {
      case 'created_on_website':
        return {
          ...defaultData,
          content: <>Request submitted on gearflow.com {byEventUser}</>,
          icon: PlusIcon,
          iconBackground: 'bg-blue-400',
        }

      case 'created_from_integration':
        return {
          ...defaultData,
          content: <>Request submitted from integration {byEventUser}</>,
          icon: PlusIcon,
          iconBackground: 'bg-blue-400',
        }

      case 'created_from_cancellation':
        return {
          ...defaultData,
          content: <>Request submitted after cancellation {byEventUser}</>,
          icon: PlusIcon,
          iconBackground: 'bg-blue-400',
        }

      case 'created_on_dashboard':
        return {
          ...defaultData,
          content: <>Request submitted manually {byEventUser}</>,
          icon: PlusIcon,
          iconBackground: 'bg-blue-400',
        }

      case 'created_on_buyer':
        return {
          ...defaultData,
          content: <>Request submitted on dashboard {byEventUser}</>,
          icon: PlusIcon,
          iconBackground: 'bg-blue-400',
        }

      case 'created_on_mobile':
        return {
          ...defaultData,
          content: <>Request submitted on mobile {byEventUser}</>,
          icon: PlusIcon,
          iconBackground: 'bg-blue-400',
        }

      case 'created_from_csv':
        return {
          ...defaultData,
          content: <>Request submitted with CSV parts upload {byEventUser}</>,
          icon: PlusIcon,
          iconBackground: 'bg-blue-400',
        }
      case 'cancelled':
        return {
          ...defaultData,
          content: <>Request canceled {byEventUser}</>,
          details: event.note ? (
            <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap">{event.note}</p>
          ) : undefined,
          icon: XIcon,
          iconBackground: 'bg-red-400',
        }

      case 'closed':
        return {
          ...defaultData,
          content: <>Request closed {byEventUser}</>,
          details: event.note ? (
            <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap">{event.note}</p>
          ) : undefined,
          icon: CheckIcon,
          iconBackground: 'bg-gray-200',
        }

      case 'opened':
        return {
          ...defaultData,
          content: <>Request reopened {byEventUser}</>,
          icon: CheckIcon,
          iconBackground: 'bg-gray-300',
        }

      case 'quote_approved':
        return {
          ...defaultData,
          content: <>Request approved {byEventUser}</>,
          icon: DoubleCheck,
          iconBackground: 'bg-green-400',
        }

      case 'quote_created':
        return {
          ...defaultData,
          content: <>Request quoted {byEventUser}</>,
          icon: CheckIcon,
          iconBackground: 'bg-green-400',
        }

      case 'owner_deleted':
        return {
          ...defaultData,
          content: <>Request owner removed {byEventUser}</>,
          details: event.note ? (
            <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap">{event.note}</p>
          ) : undefined,
          icon: UserRemoveIcon,
          iconBackground: 'bg-red-400',
        }

      case 'owner_added':
        return {
          ...defaultData,
          content: <>Request owner added {byEventUser}</>,
          details: event.note ? (
            <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap">{event.note}</p>
          ) : undefined,
          icon: UserAddIcon,
          iconBackground: 'bg-indigo-400',
        }

      case 'assigned':
        return {
          ...defaultData,
          content: <>Request assigned {byEventUser}</>,
          details: event.note ? (
            <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap">{event.note}</p>
          ) : undefined,
          icon: InboxInIcon,
          iconBackground: 'bg-gray-400',
        }

      case 'assigned_deleted':
        return {
          ...defaultData,
          content: <>Request assigned supplier deleted {byEventUser}</>,
          details: event.note ? (
            <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap">{event.note}</p>
          ) : undefined,
          icon: UserRemoveIcon,
          iconBackground: 'bg-red-400',
        }

      case 'note':
        return {
          ...defaultData,
          content: <>Comment {byEventUser}</>,
          subContent:
            showStoreNames &&
            (event.privacy === 'protected' || event.privacy === 'supplier_protected') &&
            event.store?.name &&
            event.user &&
            !User.isAdmin(event.user) ? (
              <>
                To <span className="text-gray-900">{event.store?.name}</span>
              </>
            ) : undefined,
          details: event.note ? (
            <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap overflow-wrap-words">
              {event.note}
            </p>
          ) : undefined,
          icon: getNoteIcon(),
        }
      case 'fulfilled':
        return {
          ...defaultData,
          content: <>Request closed {byEventUser} - not receiving any more Quotes</>,
          icon: ClipboardCheckIcon,
        }

      case 'buyer_notification_user_added':
        return {
          ...defaultData,
          icon: UserAddIcon,
          content: (
            <>
              <span className="text-gray-900">
                {(event.data as RfqEventNotificationSubscriptionData)?.name}
              </span>{' '}
              was subscribed to email notifications {byEventUser}
            </>
          ),
        }

      case 'buyer_notification_user_removed':
        return {
          ...defaultData,
          icon: UserRemoveIcon,
          content: (
            <>
              <span className="text-gray-900">
                {(event.data as RfqEventNotificationSubscriptionData)?.name}
              </span>{' '}
              was unsubscribed from email notifications {byEventUser}
            </>
          ),
        }

      case 'viewed_receipt':
        return {
          ...defaultData,
          icon: EyeIcon,
          content: (
            <>
              Viewed{' '}
              {showStoreNames ? (
                <>
                  by{' '}
                  <span className="text-gray-900">
                    {(event.data as RfqEventViewedReceipt)?.vendorName}
                  </span>
                </>
              ) : (
                byEventUser
              )}
            </>
          ),
        }

      case 'vendor_quote_file_uploaded':
        return {
          ...defaultData,
          icon: DocumentAddIcon,
          content: <>Quote file uploaded {byEventUser}</>,
          details: (
            <div className="text-sm space-x-4">
              <A.T
                href={(event.data as RfqEventVendorQuoteFileUploadedData).fileUrl}
                target="_blank"
                rel="noreferrer"
              >
                View file
              </A.T>
              <A.T
                href={
                  event.user
                    ? `${config.adminUrl}/rfqs/${event.requestForQuoteId}/contact/${
                        event.user.id
                      }/quote/${(event.data as RfqEventVendorQuoteFileUploadedData).vendorId}?edit=1`
                    : `${config.adminUrl}/rfqs/${event.requestForQuoteId}/quote/${
                        (event.data as RfqEventVendorQuoteFileUploadedData).vendorId
                      }?edit=1`
                }
                target="_blank"
                rel="noreferrer"
              >
                Submit quote
              </A.T>
            </div>
          ),
        }

      case 'cannot_participate':
        return {
          ...defaultData,
          content: <>Vendor opted to not participate {byEventUser}</>,
          icon: XIcon,
          iconBackground: 'bg-gray-400',
        }

      case 'can_participate':
        return {
          ...defaultData,
          content: <>Vendor opted to participate {byEventUser}</>,
          icon: CheckIcon,
          iconBackground: 'bg-gray-400',
        }

      case 'vendor_closed':
        return {
          ...defaultData,
          content: <>Vendor Closed {byEventUser}</>,
          icon: XIcon,
          iconBackground: 'bg-gray-400',
        }

      case 'updated_part_request':
        return {
          ...defaultData,
          content: <>Parts request updated {byEventUser}</>,
          icon: CheckIcon,
          iconBackground: 'bg-blue-400',
        }

      case 'added_part_request':
        return {
          ...defaultData,
          content: <>Parts request added {byEventUser}</>,
          icon: PlusIcon,
          iconBackground: 'bg-blue-400',
        }

      case 'created_by_dealer':
        return {
          ...defaultData,
          content: <>Request created for vendor quote {byEventUser}</>,
          icon: PlusIcon,
          iconBackground: 'bg-blue-400',
        }

      case 'edited_shipping_address':
        return {
          ...defaultData,
          content: <>Shipping address updated {byEventUser}</>,
          icon: OfficeBuildingIcon,
        }
      default:
        return defaultData
    }
  }

const fromPayload = (payload: RfqEventPayload): RfqEvent => {
  const insertedAt = Time.fromPayload(payload.insertedAt)

  return {
    ...payload,
    occurredAt:
      'occurredAt' in payload && payload.occurredAt !== undefined
        ? Time.fromPayload(payload.occurredAt)
        : insertedAt,
    insertedAt,
  }
}

export default { fromPayload, getEventDisplayData }
