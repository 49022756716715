import { PaymentStatus } from '../../types'
import Badge from './Badge'

const badgeProps: Record<PaymentStatus, { title: string; color: string; textColor: string }> = {
  unpaid: { title: 'Unpaid', color: 'bg-blue-600', textColor: 'text-blue-50' },
  invoiced: { title: 'Invoiced', color: 'bg-blue-600', textColor: 'text-blue-50' },
  processing: { title: 'Processing', color: 'bg-blue-600', textColor: 'text-blue-50' },
  cancelled: { title: 'Cancelled', color: 'bg-gray-600', textColor: 'text-gray-50' },
  authorized: { title: 'Authorized', color: 'bg-gray-600', textColor: 'text-gray-50' },
  quote_approved: { title: 'Quote Approved', color: 'bg-gray-600', textColor: 'text-gray-50' },
  paid: { title: 'Paid', color: 'bg-green-600', textColor: 'text-green-50' },
  refunded: { title: 'Refunded', color: 'bg-red-600', textColor: 'text-red-50' },
  partially_refunded: {
    title: 'Partially Refunded',
    color: 'bg-pink-600',
    textColor: 'text-pink-50',
  },
  direct_pay: { title: 'Direct Pay', color: 'bg-gray-600', textColor: 'text-gray-50' },
}

const PaymentStatusBadge = ({ paymentStatus }: { paymentStatus: PaymentStatus }) => (
  <Badge {...badgeProps[paymentStatus]} />
)

export default PaymentStatusBadge
