import reduce from 'lodash/reduce'

import { AddProductErrors, GqlError } from '../../types'

const fromGqlErrors = (gqlErrors: GqlError[]): AddProductErrors =>
  reduce(
    gqlErrors,
    (acc, gqlError) => {
      if (gqlError.message === 'name_required') return { ...acc, name: 'is required' }
      if (gqlError.message === 'name_too_long')
        return { ...acc, name: 'is too long (max is 255 characters)' }
      if (gqlError.message === 'mpn_required') return { ...acc, mpn: 'is required' }
      if (gqlError.message === 'list_price_required') return { ...acc, listPrice: 'is required' }
      throw new Error(`Unmapped gqlError: ${gqlError.message}`)
    },
    {}
  )

export default { fromGqlErrors }
