import { ReportingStoreResult } from '../../types'

import Comparison from './Comparison'
import { Table, Tbody, Td, Thead, Tr } from './Table'

const cols = 5

const ReportingTableStoreBreakdownRFQ = ({
  storesBreakdown,
  comparisonStoresBreakdown,
}: {
  storesBreakdown: ReportingStoreResult[]
  comparisonStoresBreakdown?: ReportingStoreResult[]
}) => (
  <Table>
    <Thead>
      <Tr>
        <Td>Vendor</Td>
        <Td>Average Time to Quote (hours)</Td>
        <Td>Average Time to Quote Change</Td>
        <Td>RFQs Quoted</Td>
        <Td>RFQs Quoted Change</Td>
      </Tr>
    </Thead>
    <Tbody>
      {storesBreakdown.length === 0 ? (
        <Tr>
          <Td colSpan={cols}>No results found.</Td>
        </Tr>
      ) : (
        storesBreakdown.map((storeResult) => {
          const comparisonStoreResult = comparisonStoresBreakdown?.find(
            (comparison) => comparison.store.id === storeResult.store.id
          )
          return (
            <Tr key={storeResult.store.id ?? 'None'}>
              <Td>{storeResult.store.name ?? 'None'}</Td>
              <Td>{storeResult.averageTimeToQuote?.toFixed(2) ?? 'N/A'}</Td>
              <Td>
                {comparisonStoreResult &&
                storeResult.averageTimeToQuote &&
                comparisonStoreResult.averageTimeToQuote ? (
                  <Comparison
                    value={storeResult.averageTimeToQuote}
                    comparisonValue={comparisonStoreResult.averageTimeToQuote}
                    downIsGood
                  />
                ) : (
                  'N/A'
                )}
              </Td>
              <Td>{storeResult.rfqsQuotedCount}</Td>
              <Td>
                {comparisonStoreResult ? (
                  <Comparison
                    value={storeResult.rfqsQuotedCount}
                    comparisonValue={comparisonStoreResult.rfqsQuotedCount}
                    displayType="value"
                    toFixed={0}
                  />
                ) : (
                  'N/A'
                )}
              </Td>
            </Tr>
          )
        })
      )}
    </Tbody>
  </Table>
)

export default ReportingTableStoreBreakdownRFQ
