import { gql, useQuery } from '@apollo/client'
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query'

import { RfqReportingMetrics, RfqReportingMetricsPayload } from '../../types'

import { rfqReportingMetrics } from '@/gf/queries/commonGqlTypes'

import RequestForQuoteContext from '@/gf/modules/RequestForQuote'
import StoreOrderM from '@/gf/modules/StoreOrder'

const doc = gql(
  jsonToGraphQLQuery(
    {
      query: {
        __variables: {
          organizationId: 'String!',
          filters: '[[String]]!',
          timezone: 'String!',
        },
        rfqReportingMetrics: {
          __args: {
            organizationId: new VariableType('organizationId'),
            filters: new VariableType('filters'),
            timezone: new VariableType('timezone'),
          },
          ...rfqReportingMetrics,
          chartData: {
            ...rfqReportingMetrics,
            date: true,
          },
          allRfqs: {
            timeToQuote: true,
            storeOrder: {
              shortId: true,
              id: true,
              insertedAt: true,
              store: {
                name: true,
              },
            },
            requestForQuote: {
              shortId: true,
              id: true,
              insertedAt: true,
              owners: {
                id: true,
                name: true,
              },
            },
          },
          storesBreakdown: {
            ...rfqReportingMetrics,
            store: {
              id: true,
              name: true,
            },
          },
          ownersBreakdown: {
            ...rfqReportingMetrics,
            owner: {
              id: true,
              name: true,
            },
          },
        },
      },
    },
    { pretty: true }
  )
)

interface ReportingMetricsData {
  rfqReportingMetrics: RfqReportingMetricsPayload
}
interface ReportingMetricsVars {
  organizationId: string
  filters: string[][]
  timezone: string
}

interface Props extends Omit<ReportingMetricsVars, 'filters'> {
  filters?: ReportingMetricsVars['filters']
}
const useRfqReportingMetrics = ({ organizationId, filters = [], timezone }: Props) => {
  const { loading, error, data } = useQuery<ReportingMetricsData, ReportingMetricsVars>(doc, {
    variables: { organizationId, filters, timezone },
    fetchPolicy: 'no-cache',
  })

  let reportingMetrics: RfqReportingMetrics | undefined
  if (data) {
    reportingMetrics = {
      ...data.rfqReportingMetrics,
      allRfqs: data.rfqReportingMetrics.allRfqs.map((reportingRfqResultPayload) => ({
        ...reportingRfqResultPayload,
        storeOrder: StoreOrderM.fromPayload(reportingRfqResultPayload.storeOrder),
        requestForQuote: RequestForQuoteContext.fromPayload(
          reportingRfqResultPayload.requestForQuote
        ),
      })),
    }
  }

  return { loading, error, data: reportingMetrics }
}

useRfqReportingMetrics.Query = doc
export default useRfqReportingMetrics
