import React from 'react'
import { Provider as RollbarProvider } from '@rollbar/react'

import useConfig from '../hooks/useConfig'

const ErrorTrackerProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const config = useConfig()

  return <RollbarProvider config={config.rollbar}>{children}</RollbarProvider>
}

export default ErrorTrackerProvider
