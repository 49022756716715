import nth from 'lodash/nth'

import { Product, UploadedImage } from '../../types'

import useMsgs from '../hooks/useMsgs'
import useProductImageUppy from '../hooks/useProductImageUppy'
import useToggle from '../hooks/useToggle'
import ProductImage from '../modules/ProductImage'

import Button from './ButtonOld'
import Card from './Card'
import SpinnerSmall from './SpinnerSmall'
import UppyFileInput from './UppyFileInput'

const SourceProductImageCard = ({
  product,
  newImage,
  setNewImage,
}: {
  product: Pick<Product, 'id' | 'name' | 'images'>
  newImage?: UploadedImage | null
  setNewImage: (img?: UploadedImage | null) => void
}) => {
  const [_msgs, msgsMgr] = useMsgs()
  const [editing, editToggler] = useToggle(false)
  const [spinnerLive, spinnerToggle] = useToggle()
  const currentImage = nth(product.images, 0)

  const uppy = useProductImageUppy({
    onFilesAdded: () => spinnerToggle.on(),
    onComplete: (image) => {
      setNewImage(image)
      editToggler.off()
      spinnerToggle.off()
    },
    productId: product.id,
  })

  const imageUrl = (() => {
    if (newImage === undefined) return currentImage && ProductImage.url(currentImage, 'main')
    if (newImage === null) return undefined
    return newImage.url
  })()

  const action = (() => {
    if (newImage === null) return 'removed'
    if (newImage) return currentImage ? 'replaced' : 'added'
    return undefined
  })()

  const reset = () => {
    setNewImage(undefined)
  }

  const change = () => {
    if (newImage) setNewImage(undefined)
    if (currentImage) setNewImage(null)
    editToggler.on()
  }

  const remove = () => {
    if (newImage) setNewImage(undefined)
    if (currentImage) setNewImage(null)
  }

  return (
    <Card title="Product Image">
      <Card.Section>
        {editing ? (
          spinnerLive ? (
            <SpinnerSmall />
          ) : (
            <UppyFileInput
              uppy={uppy}
              allowedFileTypes={useProductImageUppy.allowedFileTypes}
              onFileInputError={(error) => msgsMgr.add(error, 'negative')}
            />
          )
        ) : (
          <div className="space-y-2">
            {imageUrl && (
              <img
                className="block h-32 max-w-xs overflow-hidden"
                src={imageUrl}
                alt={product.name}
              />
            )}
            {action && (
              <div className="text-sm bg-yellow-100 px-4 py-2">
                Image will be <span className="font-bold">{action}</span> when form is saved.
              </div>
            )}
          </div>
        )}
      </Card.Section>

      <Card.Section>
        <div className="flex gap-4">
          {editing ? (
            <Button className="block" title="Cancel" onClick={editToggler.off} primary={false} />
          ) : (
            <>
              <Button
                className="block"
                title={imageUrl ? 'Change' : 'Add'}
                onClick={imageUrl ? change : editToggler.on}
                primary={false}
              />
              {imageUrl && (
                <Button className="block" title="Remove" onClick={remove} primary={false} />
              )}
              {action && <Button className="block" title="Reset" onClick={reset} primary={false} />}
            </>
          )}
        </div>
      </Card.Section>
    </Card>
  )
}

export default SourceProductImageCard
