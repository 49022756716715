/**
 * Use this file to declare common graphql query object structures.
 * These objects can be used in queries using the jsonToGraphQLQuery() function.
 *
 * Do not declare any object structures specific to the Buyer or Supplier dashboards; for that use the following files:
 * - Buyers: js/buyers/queries/commonGqlTypes.ts
 * - Suppliers: js/suppliers/queries/commonGqlTypes.ts
 */

export const pagination = {
  totalPages: true,
  totalResults: true,
}

export const money = {
  amount: true,
  currency: true,
}
export const storeOrderAudit = {
  id: true,
  shippingCost: money,
  taxCost: money,
  timingDetails: true,
  pickup: true,
  lineItemAudits: {
    id: true,
    quantity: true,
    unitPrice: money,
    productId: true,
    productName: true,
  },
}

export const address = {
  firstName: true,
  lastName: true,
  lineOne: true,
  lineTwo: true,
  city: true,
  state: true,
  country: true,
  postalCode: true,
  companyName: true,
}

export const addressWithMetadata = {
  ...address,
  deliverable: true,
  rdi: true,
}

export const storeOrderEvent = {
  id: true,
  insertedAt: true,
  type: true,
  storeOrderId: true,
  storeOrderShortId: true,
  storeName: true,
  privacy: true,
  note: true,
  emailSent: true,
  user: {
    id: true,
    name: true,
  },
  transaction: {
    id: true,
    action: true,
    customer: money,
    gearflow: money,
    supplier: money,
  },
  refund: {
    id: true,
    amount: money,
  },
  additionalCharge: {
    id: true,
    name: true,
    price: money,
  },
  shipment: {
    id: true,
    shipmentItems: {
      id: true,
      quantity: true,
      lineItem: {
        id: true,
        productName: true,
        quantity: true,
      },
    },
  },
  problem: {
    id: true,
    type: true,
    details: true,
  },
  storeOrderAudit,
  diffStoreOrderAudit: storeOrderAudit,
  data: {
    id: true,
    details: true,
    payOnAccount: true,
    payOnInvoice: true,
    name: true,
    previousAddress: address,
  },
}

export const requestForQuoteEvent = {
  id: true,
  insertedAt: true,
  type: true,
  requestForQuoteId: true,
  requestForQuoteShortId: true,
  privacy: true,
  user: {
    id: true,
    name: true,
    role: true,
  },
  store: { name: true },
  note: true,
  emailSent: true,
  data: {
    id: true,
    name: true,
    fileUrl: true,
    vendorId: true,
    vendorName: true,
  },
}

export const partRequest = {
  id: true,
  mpn: true,
  description: true,
  externalId: true,
  quantity: true,
}

export const rfqReportingMetrics = {
  averageTimeToQuote: true,
  rfqsQuotedCount: true,
}

export const orderReportingMetrics = {
  total: money,
  netTotal: money,
}
