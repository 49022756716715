import Layout from '../Layout'
import H2 from '../H2'

const NotFound = () => (
  <Layout>
    <H2>Page Not Found</H2>
  </Layout>
)

export default NotFound
