import { useMutation, gql } from '@apollo/client'

import { InviteDealerForm } from '../types'

interface Data {
  inviteDealer: string
}

const mutation = gql`
  mutation InviteDealer($organizationId: ID!, $email: String!) {
    inviteDealer(organizationId: $organizationId, email: $email)
  }
`

const useInviteDealer = () => {
  const [mutate] = useMutation<Data, InviteDealerForm>(mutation)

  return (variables: InviteDealerForm) => mutate({ variables })
}

export default useInviteDealer
