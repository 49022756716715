import { ReactNode } from 'react'
import { ReportingTab, OrderReportingChartDatum } from '../../types'

import Card from './Card'
import ReportingResponsiveLineChart from './ReportingResponsiveLineChart'
import ReportingComparisonDataCard from './ReportingComparisonDataCard'

const ReportingSectionOrders = ({
  chartData,
  comparisonChartData,
  selectedChartTab,
  showComparison,
  dateIntervalDays,
  comparisonDateRange,
  dataChildren,
  comparisonDataChildren,
}: {
  chartData: OrderReportingChartDatum[]
  comparisonChartData: OrderReportingChartDatum[]
  selectedChartTab: ReportingTab
  showComparison: boolean
  dateIntervalDays?: number
  comparisonDateRange: { from: string; to: string } | undefined
  dataChildren: ReactNode
  comparisonDataChildren: ReactNode
}) => (
  <>
    <Card>
      <Card.Section>
        <div className="flex flex-grow justify-center items-center h-72">
          <ReportingResponsiveLineChart
            data={chartData}
            comparisonData={comparisonChartData}
            selectedChartTab={selectedChartTab}
            showComparison={showComparison}
            dateIntervalDays={dateIntervalDays}
          />
        </div>
      </Card.Section>
    </Card>
    <ReportingComparisonDataCard
      dataChildren={dataChildren}
      comparisonDataChildren={comparisonDataChildren}
      comparisonDateRange={comparisonDateRange}
    />
  </>
)

export default ReportingSectionOrders
