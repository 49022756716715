import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { UserIcon } from '@heroicons/react/solid'

import useConfig from '../../hooks/useConfig'

const UserMenu = () => {
  const config = useConfig()

  return (
    <div className="hidden lg:ml-2 lg:flex lg:items-center">
      {/* Profile dropdown */}
      <Menu as="div" className="relative flex-shrink-0">
        {({ open: opn }) => (
          <>
            <div>
              <Menu.Button className="bg-white rounded-full flex text-sm focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 bg-gray-200 p-2">
                <span className="sr-only">Open user menu</span>
                <UserIcon className="flex-shrink-0 h-5 w-5" aria-hidden="true" />
              </Menu.Button>
            </div>
            <Transition
              show={opn}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <Menu.Item>
                  <a
                    href={`${config.gfBaseUrl}/`}
                    className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-gray-800"
                  >
                    Gearflow.com
                  </a>
                </Menu.Item>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  )
}

export default UserMenu
