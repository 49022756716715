import { useQuery, gql, QueryHookOptions } from '@apollo/client'

import ProductM from '../modules/Product'

import { ProductPayload, Product, Pagination } from '../types'

interface Data {
  paginatedProducts: {
    products: ProductPayload[]
    pagination: Pagination
  }
}

interface Variables {
  organizationId: string
  filter?: string
  page?: number
}

const query = gql`
  query FetchProducts($organizationId: ID!, $filter: Filter, $page: Int) {
    paginatedProducts(organizationId: $organizationId, filter: $filter, page: $page) {
      products {
        id
        name
        mpn
        altMpn
        sku
        shortDescription
        longDescription
        availability
        leadTime
        leadTimeDate
        isOem
        listPrice {
          amount
          currency
        }
        shippingCost {
          amount
          currency
        }
        brand {
          id
        }
        category {
          id
        }
        images {
          variants {
            name
            url
          }
        }
      }
      pagination {
        totalPages
        totalResults
      }
    }
  }
`

const useFetchProducts = (options?: QueryHookOptions<Data, Variables>) => {
  const result = useQuery<Data, Variables>(query, options)

  const data = result.data && {
    ...result.data,
    paginatedProducts: {
      ...result.data.paginatedProducts,
      products: result.data.paginatedProducts.products.map((p): Product => ProductM.fromPayload(p)),
    },
  }

  return { ...result, data }
}

export default useFetchProducts
