import { StoreOrderProblemType } from '@/buyers/_gen/gql'
import {
  ChatIcon,
  CheckIcon,
  CreditCardIcon,
  CubeIcon,
  DocumentAddIcon,
  ExclamationIcon,
  LockClosedIcon,
  MailIcon,
  MinusIcon,
  OfficeBuildingIcon,
  PencilIcon,
  PlusIcon,
  QrcodeIcon,
  QuestionMarkCircleIcon,
  ShieldCheckIcon,
  TruckIcon,
  UserAddIcon,
  UserRemoveIcon,
  XIcon,
} from '@heroicons/react/solid'
import {
  EventAction,
  EventDisplayData,
  EventType,
  LineItemAuditDiff,
  LineItemAuditPayload,
  LineItemAudit as LineItemAuditT,
  StoreOrderAuditDiff,
  StoreOrderAuditPayload,
  StoreOrderAudit as StoreOrderAuditT,
  StoreOrderEvent,
  StoreOrderEventCancelData,
  StoreOrderEventEditedShippingAddressData,
  StoreOrderEventNotificationSubscriptionData,
  StoreOrderEventPayDirectInvoiceSentData,
  StoreOrderEventPayload,
  StoreOrderEventQuoteApprovedData,
  Shipment as TShipment,
  User as UserT,
} from '../../types'
import A from '../components/A'
import Address from '../components/Address'
import AdditionalCharge from './AdditionalCharge'
import Money from './Money'
import Refund from './Refund'
import Shipment from './Shipment'
import StoreOrderM from './StoreOrder'
import StoreOrderAuditContext from './StoreOrderAudit'
import Time from './Time'
import Transaction from './Transaction'
import User from './User'

const LineItemAudit = {
  fromPayload: (payload: LineItemAuditPayload): LineItemAuditT => ({
    ...payload,
    unitPrice: Money.fromPayload(payload.unitPrice),
  }),
}

const StoreOrderAudit = {
  fromPayload: (payload: StoreOrderAuditPayload): StoreOrderAuditT => ({
    ...payload,
    shippingCost: Money.fromPayload(payload.shippingCost),
    taxCost: Money.fromPayload(payload.taxCost),
    lineItemAudits: payload.lineItemAudits.map(LineItemAudit.fromPayload),
  }),
}

const fromPayload = (payload: StoreOrderEventPayload): StoreOrderEvent => {
  const insertedAt = Time.fromPayload(payload.insertedAt)

  return {
    ...payload,
    transaction: payload.transaction && Transaction.fromPayload(payload.transaction),
    refund: payload.refund ? Refund.fromPayload(payload.refund) : undefined,
    additionalCharge: payload.additionalCharge
      ? AdditionalCharge.fromPayload(payload.additionalCharge)
      : undefined,
    storeOrderAudit: payload.storeOrderAudit
      ? StoreOrderAudit.fromPayload(payload.storeOrderAudit)
      : undefined,
    diffStoreOrderAudit: payload.diffStoreOrderAudit
      ? StoreOrderAudit.fromPayload(payload.diffStoreOrderAudit)
      : undefined,
    shipment: payload.shipment ? Shipment.fromPayload(payload.shipment) : undefined,
    occurredAt:
      'occurredAt' in payload && payload.occurredAt !== undefined
        ? Time.fromPayload(payload.occurredAt)
        : insertedAt,
    insertedAt: Time.fromPayload(payload.insertedAt),
  }
}

const getLineItemAuditDiffDetails = (lineItemAuditDiff: LineItemAuditDiff) => {
  if (!lineItemAuditDiff.old && lineItemAuditDiff.new)
    return (
      <li key={lineItemAuditDiff.new.id}>
        Added {lineItemAuditDiff.new.productName} at quantity {lineItemAuditDiff.new.quantity}
      </li>
    )
  if (lineItemAuditDiff.old && !lineItemAuditDiff.new)
    return <li key={lineItemAuditDiff.old.id}>Removed {lineItemAuditDiff.old.productName}</li>
  if (
    lineItemAuditDiff.old &&
    lineItemAuditDiff.new &&
    lineItemAuditDiff.old.quantity !== lineItemAuditDiff.new.quantity
  )
    return (
      <li key={lineItemAuditDiff.new.id}>
        Changed quantity of {lineItemAuditDiff.new.productName} from{' '}
        {lineItemAuditDiff.old.quantity} to {lineItemAuditDiff.new.quantity}
      </li>
    )
  return null
}

const getStoreOrderAuditDiffDetails = (storeOrderAuditDiff: StoreOrderAuditDiff) => (
  <>
    <p className="text-gray-900">Changes</p>
    <ul className="list-inside list-disc leading-snug text-sm text-gray-500">
      {storeOrderAuditDiff.lineItemDiffs.map(getLineItemAuditDiffDetails)}
      {storeOrderAuditDiff.oldPickup !== storeOrderAuditDiff.newPickup && (
        <li key="pickup-update">
          Delivery Method: {storeOrderAuditDiff.newPickup ? 'Pickup' : 'Ship'}
        </li>
      )}
      {!Money.equals(storeOrderAuditDiff.newShippingCost, storeOrderAuditDiff.oldShippingCost) && (
        <li key="shipping-update">
          Changed shipping from {Money.format(storeOrderAuditDiff.oldShippingCost)} to{' '}
          {Money.format(storeOrderAuditDiff.newShippingCost)}
        </li>
      )}
      {storeOrderAuditDiff.oldTimingDetails !== storeOrderAuditDiff.newTimingDetails && (
        <li key="leadtime-update">
          Changed shipping/lead time{' '}
          {storeOrderAuditDiff.oldTimingDetails === ''
            ? ''
            : `from ${storeOrderAuditDiff.oldTimingDetails}`}{' '}
          to {storeOrderAuditDiff.newTimingDetails}
        </li>
      )}
    </ul>
  </>
)

const getStoreOrderAuditDetails = (storeOrderAudit: StoreOrderAuditT) => (
  <>
    <p className="text-gray-900">Order snapshot</p>
    <ul className="list-inside list-disc leading-snug text-sm text-gray-500">
      {storeOrderAudit.lineItemAudits
        .filter((liAudit) => !!liAudit.productName)
        .sort((liAudit1, liAudit2) => liAudit1.productName.localeCompare(liAudit2.productName))
        .map((lineItemAudit) => (
          <li key={lineItemAudit.id}>
            {lineItemAudit.productName} {Money.format(lineItemAudit.unitPrice)} x{' '}
            {lineItemAudit.quantity}
          </li>
        ))}
      <li>Shipping {Money.format(storeOrderAudit.shippingCost)}</li>
    </ul>
  </>
)

const getShipmentDetails = (shipment: TShipment) => (
  <>
    <ul className="list-inside list-disc leading-snug text-sm text-gray-500">
      {shipment.shipmentItems
        .filter((si) => !!si.lineItem.productName)
        .sort((si1, si2) => si1.lineItem.productName.localeCompare(si2.lineItem.productName))
        .map((shipmentItem) => (
          <li key={shipmentItem.id}>
            {shipmentItem.lineItem.productName} x {shipmentItem.quantity}
          </li>
        ))}
    </ul>
    {shipment.link ? (
      <A.T className="!text-gray-500" href={shipment.link} target="_blank">
        Track shipment
      </A.T>
    ) : shipment.carrier && shipment.tracking ? (
      <>
        <p className="text-sm text-gray-500 leading-snug">Carrier: {shipment.carrier}</p>
        <p className="text-sm text-gray-500 leading-snug">Tracking number: {shipment.tracking}</p>
      </>
    ) : null}
  </>
)

const getReadableCancelEventReason = (cancelData: StoreOrderEventCancelData) => {
  if (cancelData.details) {
    return cancelData.details
  }
  const cancelReason = StoreOrderM.CANCEL_REASONS.find((reason) => reason.id === cancelData.id)
  if (cancelReason) {
    return cancelReason.title
  }
  return 'Unknown cancel reason'
}

const getByName = (event: Pick<StoreOrderEvent, 'user'>, eventByName?: string) => {
  const gearflowAdmin = event.user && User.isAdmin(event.user) ? ' (Gearflow Admin)' : ''
  return eventByName ? (
    <>
      by{' '}
      <span className="text-gray-900">
        {eventByName}
        {gearflowAdmin}
      </span>
    </>
  ) : null
}

// Returns the base/common event display data.
// Don't put logic specific to displaying Supplier or Buyer StoreOrderEvents in here.
const getEventDisplayData = ({
  event,
  eventByName,
  showEmailSent = true,
}: {
  event: StoreOrderEvent
  eventByName?: string
  showEmailSent?: boolean
}): EventDisplayData => {
  const byName = getByName(event, eventByName)
  const emailComments = event.note ? (
    <>
      <p className="text-gray-900">Email comments</p>
      <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap">{event.note}</p>
    </>
  ) : null
  const emailDetails =
    typeof event.emailSent === 'boolean' && (emailComments || showEmailSent) ? (
      <>
        {showEmailSent && (
          <>
            {!event.emailSent && <p className="text-gray-900">Email not sent</p>}
            {event.emailSent && !emailComments && <p className="text-gray-900">Email sent</p>}
          </>
        )}
        {emailComments}
      </>
    ) : null

  const defaultData: EventDisplayData = {
    type: EventType.StoreOrder,
    id: event.id,
    sourceLink: `${window.location.origin}/orders/${event.storeOrderId}#${event.id}`,
    sourceLinkMessage:
      event.type === 'note'
        ? `Comment left on Order ${StoreOrderM.shortenId(event.storeOrderId)}`
        : null,
    datetime: event.occurredAt,
    content: <>Internal error - event type {event.type} not found</>,
    icon: QuestionMarkCircleIcon,
    iconBackground: 'bg-gray-400',
  }

  switch (event.type) {
    case 'created':
      return {
        ...defaultData,
        content: <>Order created on gearflow.com {byName}</>,
        details: event.storeOrderAudit
          ? getStoreOrderAuditDetails(event.storeOrderAudit)
          : undefined,
        hiddenDetails: emailDetails,
        icon: PlusIcon,
        iconBackground: 'bg-gray-400',
      }

    case 'created_on_dashboard':
      return {
        ...defaultData,
        content: <>Quote created manually {byName}</>,
        details: event.storeOrderAudit
          ? getStoreOrderAuditDetails(event.storeOrderAudit)
          : undefined,
        hiddenDetails: emailDetails,
        icon: PlusIcon,
        iconBackground: 'bg-blue-400',
      }

    case 'delivery_booked':
      return {
        ...defaultData,
        content: <>Delivery booked {byName}</>,
        icon: TruckIcon,
        iconBackground: 'bg-gearflow',
      }

    case 'created_from_rfq':
      return {
        ...defaultData,
        content: <>Quote created from Request {byName}</>,
        details: event.storeOrderAudit
          ? getStoreOrderAuditDetails(event.storeOrderAudit)
          : undefined,
        hiddenDetails: emailDetails,
        icon: PlusIcon,
        iconBackground: 'bg-blue-400',
      }

    case 'edited':
      return {
        ...defaultData,
        content: <>Order edited {byName} - Quote created</>,
        details: (
          <>
            {event.diffStoreOrderAudit && event.storeOrderAudit ? (
              <>
                {getStoreOrderAuditDiffDetails(
                  StoreOrderAuditContext.getStoreOrderAuditDiff(
                    event.diffStoreOrderAudit,
                    event.storeOrderAudit
                  )
                )}
              </>
            ) : (
              <></>
            )}
            {event.storeOrderAudit ? getStoreOrderAuditDetails(event.storeOrderAudit) : <></>}
          </>
        ),
        hiddenDetails: emailDetails,
        icon: PencilIcon,
        iconBackground: 'bg-blue-400',
      }

    case 'quote_approved':
      return {
        ...defaultData,
        content: (
          <>
            Quote approved {byName}
            {(event.data as StoreOrderEventQuoteApprovedData)?.payOnAccount && ' - direct pay'}
            {(event.data as StoreOrderEventQuoteApprovedData)?.payOnInvoice && ' - pay on invoice'}
          </>
        ),
        icon: CheckIcon,
        iconBackground: 'bg-gray-400',
      }

    case 'quote_denied':
      return {
        ...defaultData,
        content: <>Quote denied {byName}</>,
        details: event.note ? (
          <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap">{event.note}</p>
        ) : undefined,
        icon: XIcon,
        iconBackground: 'bg-yellow-300',
      }

    case 'approved':
      return {
        ...defaultData,
        content: (
          <>
            Order approved {byName}{' '}
            {event.transaction ? Money.format(Money.mult(event.transaction.customer, -1)) : ''}
          </>
        ),
        hiddenDetails: emailDetails,
        icon: CreditCardIcon,
        iconBackground: 'bg-yellow-300',
      }

    case 'pay_direct_invoice_sent':
      return {
        ...defaultData,
        details: (
          <A.T href={(event.data as StoreOrderEventPayDirectInvoiceSentData).link} target="_blank">
            Download Invoice
          </A.T>
        ),
        icon: DocumentAddIcon,
      }

    case 'invoice_paid':
      return {
        ...defaultData,
        content: <>Invoice marked paid {byName}</>,
        icon: CheckIcon,
        iconBackground: 'bg-gray-400',
      }

    case 'availability_updated':
      return {
        ...defaultData,
        content: <>Availability updated {byName}</>,
        icon: CheckIcon,
        iconBackground: 'bg-gray-400',
      }

    case 'pay_direct_invoice_paid':
      return {
        ...defaultData,
        content: <>Invoice marked as paid {byName}</>,
        icon: CheckIcon,
        iconBackground: 'bg-gray-400',
      }

    case 'refund_created':
      return {
        ...defaultData,
        content: (
          <>
            Refund created {byName}
            {event.refund ? ` - refunded ${Money.format(event.refund.amount)}` : ''}
          </>
        ),
        hiddenDetails: emailDetails,
        icon: MinusIcon,
        iconBackground: 'bg-pink-300',
      }

    case 'additional_charge_created':
      return {
        ...defaultData,
        content: (
          <>
            Additional charge created {byName}
            {event.additionalCharge ? ` - pending charge for ${event.additionalCharge.name}` : ''}
          </>
        ),
        hiddenDetails: emailDetails,
        icon: PlusIcon,
        iconBackground: 'bg-grey-400',
      }

    case 'additional_charge_approved':
      return {
        ...defaultData,
        content: (
          <>
            Additional charge approved {byName}
            {event.additionalCharge ? ` - charged for ${event.additionalCharge.name}` : ''}
          </>
        ),
        icon: CheckIcon,
        iconBackground: 'bg-green-400',
      }

    case 'additional_charge_denied':
      return {
        ...defaultData,
        content: (
          <>
            Additional charge denied {byName}
            {event.additionalCharge ? ` - ${event.additionalCharge.name}` : ''}
          </>
        ),
        details: event.note ? (
          <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap">{event.note}</p>
        ) : undefined,
        icon: XIcon,
        iconBackground: 'bg-yellow-300',
      }

    case 'shipment_configured':
      return {
        ...defaultData,
        content: <>Shipment label created {byName}</>,
        details: event.shipment ? getShipmentDetails(event.shipment) : undefined,
        icon: QrcodeIcon,
        iconBackground: 'bg-gray-400',
      }
    case 'shipped':
      return {
        ...defaultData,
        content: <>Shipped {byName}</>,
        details: event.shipment ? getShipmentDetails(event.shipment) : undefined,
        hiddenDetails: emailDetails,
        icon: TruckIcon,
        iconBackground: 'bg-green-400',
      }
    case 'ready_for_pickup':
      if (
        // Convert to lowercase because some of our APIs use enums (all uppercase) while others don't
        (
          event.data as { deliveryMethod: string | null } | undefined
        )?.deliveryMethod?.toLowerCase() === 'vendor_delivery'
      )
        return {
          ...defaultData,
          content: <>Out For Delivery {byName}</>,
          hiddenDetails: emailDetails,
          icon: TruckIcon,
          iconBackground: 'bg-green-400',
        }

      return {
        ...defaultData,
        content: <>Ready for pickup {byName}</>,
        hiddenDetails: emailDetails,
        icon: CubeIcon,
        iconBackground: 'bg-green-400',
      }
    case 'cancelled':
      return {
        ...defaultData,
        content: <>Order cancelled {byName}</>,
        hiddenDetails: emailDetails,
        icon: XIcon,
        iconBackground: 'bg-red-400',
      }

    case 'note':
      return {
        ...defaultData,
        content: <>Comment {byName}</>,
        details: event.note ? (
          <p className="leading-snug text-sm text-gray-500 whitespace-pre-wrap overflow-wrap-words">
            {event.note}
          </p>
        ) : undefined,
        icon: (() => {
          if (event.privacy === 'PUBLIC') {
            return ChatIcon
          }
          if (event.privacy === 'PRIVATE') {
            return LockClosedIcon
          }
          return ShieldCheckIcon
        })(),
      }

    case 'edited_email_resent':
      return {
        ...defaultData,
        content: <>Edit order email resent {byName}</>,
        hiddenDetails: event.note ? emailDetails : undefined,
        icon: MailIcon,
      }

    case 'confirmation_email_resent':
      return {
        ...defaultData,
        content: <>Confirmation email resent {byName}</>,
        hiddenDetails: event.note ? emailDetails : undefined,
        icon: MailIcon,
      }

    case 'buyer_notification_user_added':
      return {
        ...defaultData,
        icon: UserAddIcon,
        content: (
          <>
            <span className="text-gray-900">
              {(event.data as StoreOrderEventNotificationSubscriptionData).name}
            </span>{' '}
            was subscribed to notifications {byName}
          </>
        ),
      }

    case 'buyer_notification_user_removed':
      return {
        ...defaultData,
        icon: UserRemoveIcon,
        content: (
          <>
            <span className="text-gray-900">
              {(event.data as StoreOrderEventNotificationSubscriptionData).name}
            </span>{' '}
            was unsubscribed from notifications {byName}
          </>
        ),
      }

    case 'edited_shipping_address':
      return {
        ...defaultData,
        icon: OfficeBuildingIcon,
        content: <>Shipping address updated {byName}</>,
        hiddenDetails: (() => {
          const data = event.data as StoreOrderEventEditedShippingAddressData
          return (
            <>
              {event.data && (
                <div className="text-sm text-gray-700 mb-2">
                  Previous address:
                  {data.previousAddress ? (
                    <Address className="text-gray-500" address={data.previousAddress} />
                  ) : (
                    <span className="text-gray-500"> None</span>
                  )}
                </div>
              )}
              {defaultData.hiddenDetails}
            </>
          )
        })(),
      }

    case 'shipment_received':
      return {
        ...defaultData,
        icon: TruckIcon,
        iconBackground: 'bg-green-400',
        details: event.shipment ? getShipmentDetails(event.shipment) : undefined,
        content: (
          <>
            <span className="text-gray-500">Shipment received by </span>
            <span className="text-gray-900">{event.user?.name}</span>
          </>
        ),
      }

    case 'edit_shipment':
      return {
        ...defaultData,
        content: <>Shipment Edited {byName}</>,
        details: event.shipment ? getShipmentDetails(event.shipment) : undefined,
        hiddenDetails: emailDetails,
        icon: TruckIcon,
        iconBackground: 'bg-green-400',
      }

    case 'problem_reported':
      return {
        ...defaultData,
        icon: ExclamationIcon,
        iconBackground: 'bg-yellow-300',
        content: <>Problem reported {byName}</>,
        details: event.problem ? (
          <>
            {event.problem.type !== StoreOrderProblemType.Other && (
              <p className="text-gray-900">
                {StoreOrderM.getStoreOrderProblemTypeDisplay(event.problem.type)}
              </p>
            )}
            <p className="whitespace-pre-wrap">{event.problem.details}</p>
          </>
        ) : undefined,
      }

    case 'problem_resolved':
      return {
        ...defaultData,
        icon: CheckIcon,
        iconBackground: 'bg-green-400',
        content: <>Problem resolved {byName}</>,
        details: event.problem ? (
          <>
            {event.problem.type !== StoreOrderProblemType.Other && (
              <p className="text-gray-900">
                {StoreOrderM.getStoreOrderProblemTypeDisplay(event.problem.type)}
              </p>
            )}
            <p className="whitespace-pre-wrap">{event.problem.details}</p>
          </>
        ) : undefined,
      }

    case 'shipment_delivered':
      return {
        ...defaultData,
        icon: TruckIcon,
        iconBackground: 'bg-green-400',
        content: <>Shipment delivered</>,
        details: event.shipment ? getShipmentDetails(event.shipment) : undefined,
      }

    case 'credit_memo':
      return {
        ...defaultData,
        icon: CreditCardIcon,
        iconBackground: 'bg-gray-300',
        content: (
          <>
            <span className="text-gray-500">Credit memo added by </span>
            <span className="text-gray-900">{event.user?.name}</span>
          </>
        ),
      }

    case 'credit_memo_received':
      return {
        ...defaultData,
        icon: CreditCardIcon,
        iconBackground: 'bg-gray-300',
        content: (
          <>
            <span className="text-gray-500">Credit memo received by </span>
            <span className="text-gray-900">{event.user?.name}</span>
          </>
        ),
      }

    case 'completed':
      return {
        ...defaultData,
        icon: CheckIcon,
        iconBackground: 'bg-green-400',
        content: <>Order completed {byName}</>,
      }

    case 'pay_direct_invoice_billed':
      return {
        ...defaultData,
        icon: CheckIcon,
        iconBackground: 'bg-gray-300',
        content: <>Invoice marked as billed to the customer {byName}</>,
      }

    default:
      return defaultData
  }
}

const getSupplierEventDisplayData =
  (
    user: Pick<UserT, 'role'>,
    onClickResendConfirmationEmail?: React.MouseEventHandler<HTMLButtonElement>,
    onClickResendQuoteEmail?: (event: StoreOrderEvent) => void
  ) =>
  (event: Omit<StoreOrderEvent, 'problem'>): EventDisplayData => {
    // Common/default display data
    const defaultData: EventDisplayData = {
      ...getEventDisplayData({
        event,
        eventByName: event.user ? event.user.name || '(unknown)' : undefined,
      }),
    }
    const byName = getByName(event, event.user ? event.user.name || '(unknown)' : undefined)

    // Actions
    const resendConfirmationEmailAction: EventAction | undefined = onClickResendConfirmationEmail
      ? {
          title: 'Resend email',
          onClick: onClickResendConfirmationEmail,
          hiddenForStates: [],
        }
      : undefined

    const resendQuoteEmailAction: EventAction | undefined = onClickResendQuoteEmail
      ? {
          title: 'Resend email',
          onClick: () => onClickResendQuoteEmail(event),
          hiddenForStates: [
            'PROCESSING',
            'PARTIALLY_SHIPPED',
            'SHIPPED',
            'READY_FOR_PICKUP',
            'REFUNDED',
            'CANCELLED',
          ],
        }
      : undefined

    // Event Display Data - only include cases for events that differ from the common/default display data
    // (events displayed differently for suppliers & buyers)
    switch (event.type) {
      case 'created':
        return {
          ...defaultData,
          action: resendConfirmationEmailAction,
        }
      case 'created_on_dashboard':
        return {
          ...defaultData,
          action: resendQuoteEmailAction,
        }
      case 'created_from_rfq':
        return {
          ...defaultData,
          action: resendQuoteEmailAction,
        }
      case 'edited':
        return {
          ...defaultData,
          action: resendQuoteEmailAction,
        }
      case 'cancelled':
        return {
          ...defaultData,
          hiddenDetails: (
            <>
              {event.data && (
                <p className="text-sm text-gray-700 mb-2">
                  {getReadableCancelEventReason(event.data as StoreOrderEventCancelData)}
                </p>
              )}
              {defaultData.hiddenDetails}
            </>
          ),
          // TODO: add internal cancel reason
        }
      case 'edited_email_resent':
        return {
          ...defaultData,
          action: resendQuoteEmailAction,
        }
      case 'confirmation_email_resent':
        return {
          ...defaultData,
          action: resendConfirmationEmailAction,
        }
      case 'note':
        return {
          ...defaultData,
          icon:
            User.isAdmin(user) && event.privacy === 'BUYER_PROTECTED'
              ? ShieldCheckIcon
              : defaultData.icon,
        }
      case 'pay_direct_invoice_sent':
        return {
          ...defaultData,
          content: <>Invoice sent {byName}</>,
        }
      default:
        return defaultData
    }
  }

// Returns name of the entity who did the event (either the user.name or the storeName)
const getBuyerEventByName = (event: StoreOrderEvent, storeName: string) => {
  // Return event.user?.name when the buyer did the action
  if (
    [
      'quote_approved',
      'quote_denied',
      'additional_charge_approved',
      'additional_charge_denied',
      'note',
      'buyer_notification_user_added',
      'buyer_notification_user_removed',
      'edited_shipping_address',
      'pay_direct_invoice_paid',
      'problem_reported',
      'problem_resolved',
      'pay_direct_invoice_billed',
    ].includes(event.type)
  )
    return event.user ? event.user.name || '(unknown)' : undefined
  // Return storeName when the supplier did the action
  return storeName
}

const getBuyerEventDisplayData = (event: StoreOrderEvent): EventDisplayData => {
  // Common/default display data
  const defaultData: EventDisplayData = getEventDisplayData({
    event,
    eventByName: getBuyerEventByName(event, event.storeName),
    showEmailSent: false,
  })

  // Event Display Data - only include cases for events that differ from the common/default display data
  // (events displayed differently for suppliers & buyers)
  switch (event.type) {
    case 'pay_direct_invoice_sent':
      return {
        ...defaultData,
        content: <>Invoice received</>,
      }
    default:
      return defaultData
  }
}

export default {
  fromPayload,
  getStoreOrderAuditDiffDetails,
  getStoreOrderAuditDetails,
  getShipmentDetails,
  getReadableCancelEventReason,
  getSupplierEventDisplayData,
  getBuyerEventDisplayData,
  getEventDisplayData,
}
