import { ReportingRfqResult } from '../../types'

import RequestForQuoteContext from '@/gf/modules/RequestForQuote'
import Time from '@/gf/modules/Time'

import A from '@/gf/components/A'
import { Table, Tbody, Td, Thead, Tr } from '@/gf/components/Table'

const cols = 7

const ReportingTableRFQ = ({
  allRfqs,
  showDealer = false,
  linkPrefix = '',
}: {
  allRfqs: ReportingRfqResult[]
  showDealer?: boolean
  linkPrefix?: string
}) => (
  <Table>
    <Thead>
      <Tr>
        <Td>Time to Quote (hours)</Td>
        {showDealer && <Td>Dealer</Td>}
        <Td>RFQ Owner</Td>
        <Td>RFQ</Td>
        <Td>RFQ Created At</Td>
        <Td>Order</Td>
        <Td>Order Created At</Td>
      </Tr>
    </Thead>
    <Tbody>
      {allRfqs.length === 0 ? (
        <Tr>
          <Td colSpan={cols}>No results found.</Td>
        </Tr>
      ) : (
        allRfqs.map(({ timeToQuote, storeOrder, requestForQuote }) => (
          <Tr key={(storeOrder || requestForQuote).id}>
            <Td>{timeToQuote.toFixed(2)}</Td>
            {showDealer && <Td>{storeOrder.store.name}</Td>}
            <Td>{RequestForQuoteContext.ownersToString(requestForQuote.owners)}</Td>
            <Td>
              <A.T href={`${linkPrefix}/rfqs/${requestForQuote.id}`} target="_blank">
                {requestForQuote.shortId}
              </A.T>
            </Td>
            <Td>{Time.toString(requestForQuote.insertedAt)}</Td>
            <Td>
              <A.T href={`${linkPrefix}/orders/${storeOrder.id}`} target="_blank">
                {storeOrder.shortId}
              </A.T>
            </Td>
            <Td>{Time.toString(storeOrder.insertedAt)}</Td>
          </Tr>
        ))
      )}
    </Tbody>
  </Table>
)

export default ReportingTableRFQ
