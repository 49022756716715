import React from 'react'

import ConfigContext from '../contexts/Config'

const useConfig = () => {
  const config = React.useContext(ConfigContext)

  if (config === undefined) throw new Error('must be used within provider')

  return config
}

export default useConfig
