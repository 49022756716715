/**
 * Library of Scorcard components
 */
import { Money } from '../../types'

import Scorecard from './Scorecard'

interface NumberValues {
  value?: number | null
  comparisonValue?: number | null
}
interface MoneyValues {
  value?: Money | null
  comparisonValue?: Money | null
}

const AverageTimeToQuote = (props: NumberValues) => (
  <Scorecard {...props} name="Avg. Time to Quote (hours)" downIsGood />
)

const RfqsQuoted = (props: NumberValues) => (
  <Scorecard {...props} name="RFQs Quoted" valueToFixed={0} />
)

const PartSales = (props: MoneyValues) => <Scorecard {...props} name="Gross Part Sales" />

const NetPartSales = (props: MoneyValues) => <Scorecard {...props} name="Net Part Sales" />

export default {
  AverageTimeToQuote,
  RfqsQuoted,
  PartSales,
  NetPartSales,
}
