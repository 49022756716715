import React from 'react'

import MsgrContext from '../contexts/Msgr'

const useMsgr = () => {
  const result = React.useContext(MsgrContext)

  if (result === undefined) throw new Error('must be used within provider')

  return result
}

export default useMsgr
