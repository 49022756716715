import { titleCase } from 'title-case'

import AccountMachine from './AccountMachine'
import IsNotFilter from './filters/IsNot'
import RfqEvent from './RfqEvent'
import StoreOrder from './StoreOrder'
import Time from './Time'
import User from './User'
import { ShippingOptions, StoreOrder as StoreOrderT } from '@/buyers/_gen/gql'

import {
  RequestForQuote,
  RequestForQuotePayload,
  ShippingOptionDisplay,
  User as TUser,
  VendorQuoteFile,
  VendorQuoteFilePayload,
} from '../../types'

const shippingOptionToDisplay = (si?: ShippingOptions): ShippingOptionDisplay => {
  if (si === ShippingOptions.Standard) {
    return ShippingOptionDisplay.Standard
  }
  if (si === ShippingOptions.Expedited) {
    return ShippingOptionDisplay.Expedited
  }
  if (si === ShippingOptions.Overnight) {
    return ShippingOptionDisplay.Overnight
  }
  return ShippingOptionDisplay.None
}

const isUrgent = (rfq: RequestForQuote): boolean => {
  const shippingOption =
    (rfq.shippingOption && (titleCase(rfq.shippingOption) as ShippingOptionDisplay)) ||
    rfq.shippingOption

  return !!(
    shippingOption &&
    [ShippingOptionDisplay.Expedited, ShippingOptionDisplay.Overnight].includes(shippingOption)
  )
}

const shortenId = (id: string) => id.split('-')[0]

const vendorQuoteFileFromPayload = (payload: VendorQuoteFilePayload): VendorQuoteFile => ({
  ...payload,
  insertedAt: Time.fromPayload(payload.insertedAt),
})

const fromPayload = (payload: RequestForQuotePayload): RequestForQuote => ({
  ...payload,
  insertedAt: Time.fromPayload(payload.insertedAt),
  neededBy: payload.neededBy ? Time.fromPayload(payload.neededBy) : null,
  events: payload.events && payload.events.map(RfqEvent.fromPayload),
  storeOrders: payload.storeOrders && payload.storeOrders.map(StoreOrder.fromPayload),
  shippingOption: shippingOptionToDisplay(payload.shippingOption),
  accountMachines:
    payload.orgMachines &&
    payload.orgMachines.map((accountMachine) => AccountMachine.fromPayload(accountMachine)),
  vendorQuoteFiles:
    payload.vendorQuoteFiles &&
    payload.vendorQuoteFiles.map((vendorQuoteFile) => vendorQuoteFileFromPayload(vendorQuoteFile)),
})

const ownersToString = (requestForQuoteOwners: TUser[]) =>
  requestForQuoteOwners.map((user) => user.name).join(', ')

const canDisplayCustomerContactInfo = (
  rfq: {
    storeOrders: Pick<StoreOrderT, 'state'>[]
  },
  user: { role: string }
) => {
  if (User.isAdmin(user)) return true

  return (
    rfq.storeOrders.filter((so) => StoreOrder.canDisplayCustomerContactInfo(so, user)).length > 0
  )
}

const buyerOpenRFQsFilters = () => [
  IsNotFilter.build({
    fieldId: 'state',
    selectedOption: 'fulfilled',
  }),
  IsNotFilter.build({
    fieldId: 'state',
    selectedOption: 'closed',
  }),
  IsNotFilter.build({
    fieldId: 'state',
    selectedOption: 'cancelled',
  }),
]

export default {
  fromPayload,
  shortenId,
  isUrgent,
  ownersToString,
  canDisplayCustomerContactInfo,
  buyerOpenRFQsFilters,
  shippingOptionToDisplay,
  vendorQuoteFileFromPayload,
}
