import React from 'react'
import { NavLink, Link } from 'react-router-dom'

import MobileMenu from './Layout/MobileMenu'
import UserMenu from './Layout/UserMenu'
import Messages from './Layout/Messages'

const Layout: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const navigation = [
    { name: 'Products', path: '/products' },
    { name: 'Dealers', path: '/dealers' },
    { name: 'Reports', path: '/reports' },
  ]

  return (
    <div className="relative min-h-screen bg-gray-100 border-t-8 border-red-700">
      <header className="bg-white shadow">
        <div className="mx-auto px-4 lg:px-8">
          <div className="flex justify-between h-16">
            <div className="flex gap-6">
              <div className="flex items-center">
                <Link to="/">
                  <img
                    className="block h-6 w-auto"
                    src="/images/favicon-32x32.png"
                    alt="Gearflow logo"
                  />
                </Link>
              </div>
              <nav aria-label="Global" className="hidden lg:flex lg:items-center lg:space-x-4">
                {navigation.map((item) => (
                  <NavLink
                    to={item.path}
                    key={item.name}
                    className={({ isActive }) =>
                      `px-3 py-2 text-gray-900 text-sm ${isActive ? 'bg-gray-100 rounded' : ''}`
                    }
                  >
                    {item.name}
                  </NavLink>
                ))}
              </nav>
            </div>
            <div className="flex items-center">
              <MobileMenu navigation={navigation} />
              <UserMenu />
            </div>
          </div>
        </div>
      </header>
      <Messages />
      <div className="px-4 py-10 lg:px-8 ">{children}</div>
    </div>
  )
}

export default Layout
