import { useMemo } from 'react'
import { ApolloClient, InMemoryCache, ApolloProvider } from '@apollo/client'

import useConfig from '../hooks/useConfig'

const GqlClientProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const config = useConfig()

  const client = useMemo(
    () =>
      new ApolloClient({
        uri: `${config.gqlBaseUrl}/gql`,
        cache: new InMemoryCache(),
        credentials: 'include',
        defaultOptions: {
          query: { fetchPolicy: 'no-cache' },
          watchQuery: { fetchPolicy: 'no-cache', nextFetchPolicy: 'no-cache' },
        },
      }),
    [config.gqlBaseUrl]
  )

  return <ApolloProvider client={client}>{children}</ApolloProvider>
}

export default GqlClientProvider
