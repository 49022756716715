import React from 'react'

import MsgrContext from '../contexts/Msgr'
import useLocalMsgr from '../hooks/useLocalMsgr'

import { type Msgr } from '../types'

const Msgr: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const msgr = useLocalMsgr()
  return <MsgrContext.Provider value={msgr}>{children}</MsgrContext.Provider>
}

export default Msgr
