import { Fragment } from 'react'
import { Transition } from '@headlessui/react'
import { XIcon } from '@heroicons/react/solid'
import { CheckCircleIcon, XCircleIcon, ExclamationCircleIcon } from '@heroicons/react/outline'

import useMsgr from '../hooks/useMsgr'

import { Msg } from '../../types'

const iconByTone = {
  positive: <CheckCircleIcon className="h-6 w-6 text-green-400" aria-hidden="true" />,
  negative: <XCircleIcon className="h-6 w-6 text-red-400" aria-hidden="true" />,
  neutral: <ExclamationCircleIcon className="h-6 w-6 text-blue-400" aria-hidden="true" />,
}

const Message = ({ msg }: { msg: Msg }) => {
  const msgr = useMsgr()

  return (
    <div className="w-full flex flex-col items-center space-y-4 sm:items-end">
      <Transition
        appear
        show
        as={Fragment}
        enter="transform ease-out duration-300 transition"
        enterFrom="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enterTo="translate-y-0 opacity-100 sm:translate-x-0"
        leave="transition ease-in duration-100"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        <div className="max-w-sm w-full bg-white shadow-lg rounded-lg pointer-events-auto ring-1 ring-black ring-opacity-5 overflow-hidden">
          <div className="flex items-start gap-2 p-4">
            <div className="">{iconByTone[msg.tone]}</div>
            <div className="grow leading-6 text-sm text-gray-700">{msg.text}</div>
            <button
              type="button"
              className="bg-white rounded-md inline-flex text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
              onClick={() => {
                msgr.remove(msg.id)
              }}
            >
              <span className="sr-only">Close</span>
              <XIcon className="h-5 w-5" aria-hidden="true" />
            </button>
          </div>
        </div>
      </Transition>
    </div>
  )
}

export default Message
