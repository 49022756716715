import { gql, useQuery } from '@apollo/client'

import { Category } from '../../types'

const doc = gql`
  query FetchCategories {
    categories {
      id
      parentId
      name
    }
  }
`

const useCategories = () => useQuery<{ categories: Category[] }>(doc, { fetchPolicy: 'no-cache' })

export default useCategories
