import React from 'react'
import { v4 as uuid } from 'uuid'

import { Msgr, Msg, MsgTone } from '../types'

// Remove messages after 10 seconds
const MSG_TIME = 10000

const useLocalMsgr = (): Msgr => {
  const [msgs, setMsgs] = React.useState<Msg[]>([])

  const fetch = () => msgs

  const remove = (msgId: string) => {
    setMsgs((prevMsgs) => prevMsgs.filter((prevMsg) => prevMsg.id !== msgId))
  }

  const clear = () => {
    setMsgs([])
  }

  const add = (text: string, tone: MsgTone = 'neutral') => {
    const newMsg = { id: uuid(), text, tone, createdAt: new Date() }
    setMsgs((prevMsgs) => [...prevMsgs, newMsg])
    setTimeout(() => remove(newMsg.id), MSG_TIME)
  }

  const addUnknownError = () => {
    add('Something went wrong. Please contact support.', 'negative')
  }

  return { fetch, add, addUnknownError, remove, clear }
}

export default useLocalMsgr
