import Money from '@/gf/modules/Money'

import { BaseProductPayload } from '../types'

const fromPayload = <Payload extends BaseProductPayload>(payload: Payload) => ({
  ...payload,
  listPrice: payload.listPrice && Money.fromPayload(payload.listPrice),
  shippingCost: payload.shippingCost && Money.fromPayload(payload.shippingCost),
})

export default { fromPayload }
