import { ReportingOrderResult, RequestForQuote, Store, StoreOrder } from '../../types'

import MoneyContext from '@/gf/modules/Money'
import Time from '@/gf/modules/Time'

import { StoreOrderStep } from '@/dealers/_gen/gql'
import StoreOrderStepBadge from '@/dealers/components/StoreOrderStepBadge'
import A from '@/gf/components/A'
import PaymentStatusBadge from '@/gf/components/PaymentStatusBadge'
import { Table, Tbody, Td, Thead, Tr } from '@/gf/components/Table'
import Tooltip from '@/gf/components/Tooltip'
import RequestForQuoteContext from '@/gf/modules/RequestForQuote'

type StoreOrderT = Omit<ReportingOrderResult, 'storeOrder' | 'requestForQuote'> & {
  storeOrder: Pick<
    StoreOrder,
    'id' | 'shortId' | 'state' | 'deliveryMethod' | 'insertedAt' | 'paymentStatus'
  > & {
    store: Pick<Store, 'name'>
    step: StoreOrderStep
  }
  requestForQuote?: Pick<RequestForQuote, 'id' | 'shortId' | 'owners'>
}

const ReportingTableOrder = ({
  allStoreOrders,
  showDealer = false,
  linkPrefix = '',
}: {
  allStoreOrders: StoreOrderT[]
  showDealer?: boolean
  linkPrefix?: string
}) => {
  const cols = 7 + (showDealer ? 1 : 0)

  return (
    <Table>
      <Thead>
        <Tr>
          <Td>Gross Part Sales</Td>
          <Td>
            Net Part Sales
            <Tooltip
              className="inline-block align-bottom ml-1"
              size="small"
              text="Gross Part Sales minus amount refunded."
            />
          </Td>
          <Td>Order</Td>
          <Td>Order Created At</Td>
          <Td>Order State</Td>
          <Td>RFQ</Td>
          <Td>RFQ Owner</Td>
          {showDealer && <Td>Dealer</Td>}
        </Tr>
      </Thead>
      <Tbody>
        {allStoreOrders.length === 0 ? (
          <Tr>
            <Td colSpan={cols}>No results found.</Td>
          </Tr>
        ) : (
          allStoreOrders.map(({ total, netTotal, storeOrder, requestForQuote }) => (
            <Tr key={storeOrder.id}>
              <Td>${MoneyContext.toString(total)}</Td>
              <Td>${MoneyContext.toString(netTotal)}</Td>
              <Td>
                <A.T href={`${linkPrefix}/orders/${storeOrder.id}`} target="_blank">
                  {storeOrder.shortId}
                </A.T>
              </Td>
              <Td>{Time.toString(storeOrder.insertedAt)}</Td>
              <Td className="space-x-2">
                <StoreOrderStepBadge step={storeOrder.step} />
                <PaymentStatusBadge paymentStatus={storeOrder.paymentStatus} />
              </Td>
              <Td>
                {requestForQuote && (
                  <A.T href={`${linkPrefix}/rfqs/${requestForQuote.id}`} target="_blank">
                    {requestForQuote.shortId}
                  </A.T>
                )}
              </Td>
              <Td>
                {requestForQuote && RequestForQuoteContext.ownersToString(requestForQuote.owners)}
              </Td>
              {showDealer && <Td>{storeOrder.store.name}</Td>}
            </Tr>
          ))
        )}
      </Tbody>
    </Table>
  )
}

export default ReportingTableOrder
