import React from 'react'
import Uppy, { UppyFile } from '@uppy/core'
import AwsS3 from '@uppy/aws-s3'

import useCreatePresignedS3URL from './useCreatePresignedS3URL'

import { UploadedImage } from '../../types'

const allowedFileTypes = ['image/*', '.jpg', '.jpeg', '.png', '.gif']

const buildUppy = ({
  fetchPresignedUrl,
  onComplete,
  onFilesAdded,
}: {
  fetchPresignedUrl: (fileName: string) => Promise<string>
  onComplete: (image: UploadedImage) => void
  onFilesAdded?: (files: UppyFile[]) => void
}) => {
  const newUppy = new Uppy({
    restrictions: {
      maxNumberOfFiles: 1,
      allowedFileTypes,
    },
    autoProceed: true,
  })

  newUppy.on('complete', (result) => {
    const item = result.successful[0]

    if (item) {
      onComplete({
        id: item.id,
        name: item.name,
        type: item.type,
        size: item.size,
        url: item.uploadURL,
      })
      newUppy.cancelAll()
    }
  })

  newUppy.on('files-added', (files) => {
    if (onFilesAdded) onFilesAdded(files)
  })

  newUppy.use(AwsS3, {
    limit: 1,
    getUploadParameters: (file) =>
      fetchPresignedUrl(file.name).then((url) => ({ method: 'PUT', url })),
  })

  return newUppy
}

const useProductImageUppy = ({
  onComplete,
  onFilesAdded,
  productId,
}: {
  onComplete: (image: UploadedImage) => void
  onFilesAdded?: (files: UppyFile[]) => void
  productId: string
}) => {
  const createPresignedS3URL = useCreatePresignedS3URL(false)

  const fetchPresignedUrl = (fileName: string) =>
    createPresignedS3URL({ key: `product_images/${productId}/${fileName}` }).then(
      ({ data }) => data?.createPresignedS3URL as string
    )

  const uppy = React.useMemo(() => buildUppy({ fetchPresignedUrl, onComplete, onFilesAdded }), [])

  return uppy
}

useProductImageUppy.allowedFileTypes = allowedFileTypes
export default useProductImageUppy
