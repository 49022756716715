import { CartesianMarkerProps } from '@nivo/core'
import { ResponsiveLine, DatumValue, PointTooltip } from '@nivo/line'
import { DateTime } from 'luxon'

import { Money, ReportingTab, RfqReportingChartDatum, OrderReportingChartDatum } from '../../types'

import MoneyContext from '../modules/Money'

const Tooltip: PointTooltip = ({ point }) => (
  <div
    key={point.id}
    className="px-2.5 py-1.5 bg-white border-gray-300 border-1 rounded-md shadow-md text-sm"
  >
    {DateTime.fromFormat(`${point.data.xFormatted}`, 'yyyy-LL-dd').toLocaleString(
      DateTime.DATE_MED
    )}
    : <span className="font-medium">{point.data.yFormatted}</span>
  </div>
)

const normalizeChartValue = (value: number | Money | null) =>
  typeof value === 'number' ? value : !value ? null : MoneyContext.toDecimal(value)

type ChartDatum = OrderReportingChartDatum | RfqReportingChartDatum
const ReportingResponsiveLineChart = ({
  data,
  comparisonData,
  selectedChartTab,
  showComparison,
  dateIntervalDays,
}: {
  data: ChartDatum[]
  comparisonData?: ChartDatum[]
  selectedChartTab: ReportingTab
  showComparison: boolean
  dateIntervalDays?: number
}) => (
  <ResponsiveLine
    data={[
      {
        id: selectedChartTab.name,
        data: data.map((datum) => ({
          x: datum.date,
          y: normalizeChartValue(datum[selectedChartTab.chartDatumKey]),
        })),
      },
    ]}
    markers={
      showComparison && normalizeChartValue(comparisonData?.[selectedChartTab.chartDatumKey])
        ? [
            {
              axis: 'y',
              value: normalizeChartValue(comparisonData?.[selectedChartTab.chartDatumKey]),
              lineStyle: { stroke: '#f4756088', strokeWidth: 2 },
              textStyle: { fontSize: '0.875rem' },
              legend: selectedChartTab.comparisonMarkerLegend,
            } as CartesianMarkerProps<DatumValue>,
          ]
        : []
    }
    margin={{
      top: 20,
      right: 45,
      bottom: 65,
      left: 80,
    }}
    xScale={{
      type: 'time',
      format: '%Y-%m-%d',
      useUTC: false,
      precision: 'day',
    }}
    xFormat="time:%Y-%m-%d"
    tooltip={Tooltip}
    yScale={{
      type: 'linear',
      min: 0,
      max:
        Math.max(
          ...data.map((datum) => normalizeChartValue(datum[selectedChartTab.chartDatumKey]) || 0),
          normalizeChartValue(comparisonData?.[selectedChartTab.chartDatumKey]) || 0
        ) * 1.05,
    }}
    yFormat={selectedChartTab.yDataFormat ?? '.2f'}
    axisBottom={{
      format: '%b %d',
      tickValues: `every ${dateIntervalDays ? Math.ceil(dateIntervalDays / 10) : 2} days`,
      legend: selectedChartTab.xLegend,
      legendOffset: 55,
      legendPosition: 'middle',
    }}
    axisLeft={{
      legend: selectedChartTab.yLegend,
      legendOffset: -70,
      legendPosition: 'middle',
      format: selectedChartTab.yFormat,
    }}
    pointSize={!dateIntervalDays ? 10 : dateIntervalDays > 30 ? 4 : dateIntervalDays > 10 ? 6 : 10}
    pointBorderWidth={2}
    pointLabelYOffset={-12}
    useMesh
  />
)

export default ReportingResponsiveLineChart
