import { Availability } from '../../types'

const formats = {
  in_stock: 'In Stock',
  back_ordered: 'Special Order',
  out_of_stock: 'Out of Stock',
}

const format = (availability: Availability) => formats[availability]

export default { format }
