import { gql, useMutation } from '@apollo/client'

import { UpdateProductFormPayload } from '../types'

interface Data {
  updateProduct: boolean
}

const mutation = gql`
  mutation UpdateProduct(
    $id: ID!
    $name: String!
    $mpn: String!
    $altMpn: String!
    $sku: String!
    $shortDescription: String!
    $longDescription: String!
    $availability: String!
    $leadTime: String!
    $isOem: Boolean!
    $listPrice: MoneyInput
    $shippingCost: MoneyInput
    $brandId: ID
    $categoryId: ID
    $imageUrl: String
    $leadTimeDate: String!
  ) {
    updateProduct(
      id: $id
      name: $name
      mpn: $mpn
      altMpn: $altMpn
      sku: $sku
      shortDescription: $shortDescription
      longDescription: $longDescription
      availability: $availability
      leadTime: $leadTime
      leadTimeDate: $leadTimeDate
      isOem: $isOem
      listPrice: $listPrice
      shippingCost: $shippingCost
      brandId: $brandId
      categoryId: $categoryId
      imageUrl: $imageUrl
    )
  }
`

const useUpdateProduct = () => {
  const [mutate] = useMutation<Data, UpdateProductFormPayload>(mutation)

  return (variables: UpdateProductFormPayload) => mutate({ variables })
}

export default useUpdateProduct
