import { useQuery, gql, QueryHookOptions } from '@apollo/client'

interface Dealer {
  id: string
  name: string
}

interface Data {
  dealers: Dealer[]
}

interface Variables {
  organizationId: string
}

const query = gql`
  query FetchDealers($organizationId: ID!) {
    dealers(organizationId: $organizationId) {
      id
      name
    }
  }
`

const useFetchDealers = (options: QueryHookOptions<Data, Variables>) =>
  useQuery<Data, Variables>(query, options)

export default useFetchDealers
