import { gql, useMutation } from '@apollo/client'

import { AddProductFormPayload } from '../types'

interface Data {
  addProduct: {
    id: string
  }
}

const mutation = gql`
  mutation AddProduct(
    $name: String!
    $mpn: String!
    $altMpn: String!
    $sku: String!
    $shortDescription: String!
    $longDescription: String!
    $availability: String!
    $leadTime: String!
    $isOem: Boolean!
    $listPrice: MoneyInput
    $shippingCost: MoneyInput
    $brandId: ID
    $categoryId: ID
    $organizationId: ID!
  ) {
    addProduct(
      name: $name
      mpn: $mpn
      altMpn: $altMpn
      sku: $sku
      shortDescription: $shortDescription
      longDescription: $longDescription
      availability: $availability
      leadTime: $leadTime
      isOem: $isOem
      listPrice: $listPrice
      shippingCost: $shippingCost
      brandId: $brandId
      categoryId: $categoryId
      organizationId: $organizationId
    )
  }
`

const useAddProduct = () => {
  const [mutate] = useMutation<Data, AddProductFormPayload>(mutation)

  return (variables: AddProductFormPayload) => mutate({ variables })
}

export default useAddProduct
