import useSession from '../../hooks/useSession'

import Layout from '../Layout'

const Home = () => {
  const { user } = useSession()

  return <Layout>{user.organization.name}</Layout>
}

export default Home
