import { useState, useEffect } from 'react'
import { useRollbarPerson } from '@rollbar/react'

import SessionContext from '../contexts/Session'
import { useFetchSessionQuery } from '../_gen/gql'
import useConfig from '../hooks/useConfig'

const SessionProvider = ({ children }: { children: React.ReactNode }) => {
  const config = useConfig()
  const newSession = useFetchSessionQuery().data?.session
  const [session, setSession] = useState(newSession)
  useRollbarPerson({ email: session?.user?.email })

  useEffect(() => {
    if (newSession) setSession(newSession)
  }, [!newSession])

  if (!session) return null

  if (!session.user) {
    // unauthenticated
    window.location.href = `${config.gfBaseUrl}/login?return_path=${encodeURIComponent(
      window.location.href
    )}`
    return null
  }

  if (!session.user.organization) {
    // unauthorized
    window.location.href = `${config.gfBaseUrl}/`
    return null
  }

  // rebuild session as a hack to convince TS it's an AuthorizedSession
  const authorizedSession = {
    ...session,
    user: { ...session.user, organization: session.user.organization },
  }

  return <SessionContext.Provider value={authorizedSession}>{children}</SessionContext.Provider>
}

export default SessionProvider
