import React from 'react'

import useInviteDealer from '../../../hooks/useInviteDealer'
import useMsgr from '../../../hooks/useMsgr'
import useSession from '../../../hooks/useSession'

import Action from '@/gf/components/Action'
import Form from '@/gf/components/Form'
import TextInput from '@/gf/components/TextInput'

import { GqlError, InviteDealerForm } from '../../../types'

interface Form {
  organizationId: string
  email: string
}

const Error = {
  fromGqlError: (gqlError: GqlError): string => {
    if (gqlError.message === 'email_invalid') return "Doesn't look like an email."
    if (gqlError.message === 'dealer_exists') return 'Dealer has already joined.'
    throw new window.Error(`Unmapped gqlError: ${gqlError.message}`)
  },
}

const InviteDealer = () => {
  const msgr = useMsgr()
  const { user } = useSession()
  const [error, setError] = React.useState<string>()
  const inviteDealer = useInviteDealer()
  const initialForm = { email: '', organizationId: user.organization.id }
  const [form, setForm] = React.useState<InviteDealerForm>(initialForm)

  const onSubmit = () => {
    setError(undefined)
    msgr.clear()

    inviteDealer(form)
      .then(() => {
        msgr.add('Dealer invited.', 'positive')
        setForm(initialForm)
      })
      .catch(({ graphQLErrors }) => {
        const gqlError = graphQLErrors[0]

        if (gqlError) {
          setError(Error.fromGqlError(gqlError))
        } else {
          msgr.addUnknownError()
        }
      })
  }

  return (
    <Form onSubmit={onSubmit} className="space-y-2">
      <div className="w-72 space-y-1">
        <TextInput
          placeholder="Email"
          value={form.email}
          setValue={(email) => setForm({ ...form, email })}
        />
        {error && <div className="text-sm text-red-500">{error}</div>}
      </div>
      <Action.S type="submit">Invite</Action.S>
    </Form>
  )
}

export default InviteDealer
