import useMsgr from '../../hooks/useMsgr'

import Message from '../Message'

const Messages = () => {
  const msgr = useMsgr()

  return (
    <div
      aria-live="assertive"
      className="fixed inset-0 flex flex-col space-y-4 items-end px-4 py-6 pointer-events-none sm:p-6 sm:items-start"
    >
      {msgr.fetch().map((msg) => (
        <Message msg={msg} key={msg.id} />
      ))}
    </div>
  )
}

export default Messages
