import { useMemo, useState } from 'react'
import { JsonParam, StringParam, useQueryParams, withDefault } from 'use-query-params'
import { DateTime } from 'luxon'

import { Filter, FilterField, ReportingTab, ReportingTabSection } from '../../../types'

import useSession from '../../hooks/useSession'
import useFilters from '@/gf/hooks/useFilters'
import useOrderReportingMetrics from '../../hooks/useOrderReportingMetrics'
import useRfqReportingMetrics from '../../hooks/useRfqReportingMetrics'
import useConfig from '../../hooks/useConfig'

import DateTimeInRange from '@/gf/modules/filters/DateTimeInRange'
import ReportingM from '@/gf/modules/Reporting'
import TimeM from '@/gf/modules/Time'
import FormatFilters from '@/gf/modules/FormatFilters'
import FilterSet from '@/gf/modules/FilterSet'

import Layout from '../Layout'
import H2 from '../H2'
import Filters from '@/gf/components/Filters'
import TabSections from '@/gf/components/TabSections'
import Card from '@/gf/components/Card'
import Scorecards from '@/gf/components/Scorecards'
import Spinner from '@/gf/components/Spinner'
import ReportingSectionRFQs from '@/gf/components/ReportingSectionRFQs'
import ReportingSectionOrders from '@/gf/components/ReportingSectionOrders'
import ReportingTableRFQ from '@/suppliers/components/ReportingTableRFQ'
import { TableWrapper } from '@/gf/components/Table'
import ReportingTableStoreBreakdownRFQ from '@/gf/components/ReportingTableStoreBreakdownRFQ'
import ReportingTableOwnerBreakdownRFQ from '@/gf/components/ReportingTableOwnerBreakdownRFQ'
import ReportingTableOrder from '@/suppliers/components/ReportingTableOrder'

const fields = (dateRangeFieldId: string): FilterField[] => [
  {
    id: dateRangeFieldId,
    display: 'Created At',
    filterTypeIds: ['date_time_in_range'],
  },
  {
    id: 'store_name',
    display: 'Dealer',
    filterTypeIds: ['equals'],
  },
  {
    id: 'owner_user_name',
    display: 'RFQ Owner',
    filterTypeIds: ['equals'],
  },
]

const rfqTabs: ReportingTab[] = [
  ReportingM.Suppliers.tabAverageTimeToQuote,
  ReportingM.Suppliers.tabRfqsQuoted,
]
const orderTabs: ReportingTab[] = [
  ReportingM.Suppliers.tabGrossPartSales,
  ReportingM.Suppliers.tabNetPartSales,
]

const initFromDate = new Date()
initFromDate.setMonth(initFromDate.getMonth() - 1)
const timezone = TimeM.localTimezone()

const dateRangeFieldId = 'inserted_at'

const useQueryParamsOpts = {
  filters: withDefault<Filter[], Filter[]>(JsonParam, [
    DateTimeInRange.build({
      fieldId: dateRangeFieldId,
      from: initFromDate.toISOString(),
    }),
  ]),
  chartTabName: StringParam,
}

const Reports = () => {
  const { suppliersUrl } = useConfig()
  const { user } = useSession()
  const [{ filters, chartTabName: initChartTabName }, updateQuery] =
    useQueryParams(useQueryParamsOpts)
  const [chartTabName, setChartTabName] = useState(initChartTabName)
  const setFilters = (newFilters: Filter[]) => updateQuery({ filters: newFilters, chartTabName })
  const [stagedFilters, filtersApi] = useFilters(filters)
  const selectedRfqChartTab = useMemo(
    () => ReportingM.getSelectedChartTab(chartTabName || undefined, rfqTabs),
    [chartTabName, rfqTabs]
  )
  const selectedOrderChartTab = useMemo(
    () => ReportingM.getSelectedChartTab(chartTabName || undefined, orderTabs),
    [chartTabName, orderTabs]
  )

  // Current date range metrics
  const { data: rfqReportingData, error: rfqReportingDataError } = useRfqReportingMetrics({
    organizationId: user.organization.id,
    filters: FormatFilters.andFilters(FilterSet.toApiFilters(filters)),
    timezone,
  })
  const { data: orderReportingData, error: orderReportingDataError } = useOrderReportingMetrics({
    organizationId: user.organization.id,
    filters: FormatFilters.andFilters(FilterSet.toApiFilters(filters)),
    timezone,
  })

  // Previous date range metrics (comparison)
  const [comparisonDateRange, comparisonFilters] = useMemo(() => {
    const compDateRange = ReportingM.getComparisonDateRange(filters, dateRangeFieldId)
    const compFilters = ReportingM.getComparisonFilters(filters, compDateRange, dateRangeFieldId)
    return [compDateRange, compFilters]
  }, [filters])
  const { data: comparisonRfqData, error: comparisonRfqError } = useRfqReportingMetrics({
    organizationId: user.organization.id,
    filters: FormatFilters.andFilters(FilterSet.toApiFilters(comparisonFilters)),
    timezone,
  })
  const { data: comparisonOrderReportingData, error: comparisonOrderReportingDataError } =
    useOrderReportingMetrics({
      organizationId: user.organization.id,
      filters: FormatFilters.andFilters(FilterSet.toApiFilters(comparisonFilters)),
      timezone,
    })
  const showComparison = !!comparisonDateRange

  const dateIntervalDays =
    rfqReportingData && rfqReportingData.chartData.length > 0
      ? DateTime.fromISO(
          rfqReportingData.chartData[rfqReportingData.chartData.length - 1].date
        ).diff(DateTime.fromISO(rfqReportingData.chartData[0].date), 'days').days
      : undefined

  const tabSections: ReportingTabSection[] = [
    {
      name: ReportingM.Suppliers.tabSectionNameRFQs,
      tabs: [
        {
          name: ReportingM.Suppliers.tabAverageTimeToQuote.name,
          children: (
            <Scorecards.AverageTimeToQuote
              value={rfqReportingData?.averageTimeToQuote}
              comparisonValue={showComparison ? comparisonRfqData?.averageTimeToQuote : undefined}
            />
          ),
        },
        {
          name: ReportingM.Suppliers.tabRfqsQuoted.name,
          children: (
            <Scorecards.RfqsQuoted
              value={rfqReportingData?.rfqsQuotedCount}
              comparisonValue={showComparison ? comparisonRfqData?.rfqsQuotedCount : undefined}
            />
          ),
        },
      ],
    },
    {
      name: ReportingM.Suppliers.tabSectionNameOrders,
      tabs: [
        {
          name: ReportingM.Suppliers.tabGrossPartSales.name,
          children: (
            <Scorecards.PartSales
              value={orderReportingData?.total}
              comparisonValue={showComparison ? comparisonOrderReportingData?.total : undefined}
            />
          ),
        },
        {
          name: ReportingM.Suppliers.tabNetPartSales.name,
          children: (
            <Scorecards.NetPartSales
              value={orderReportingData?.netTotal}
              comparisonValue={showComparison ? comparisonOrderReportingData?.netTotal : undefined}
            />
          ),
        },
      ],
    },
  ]
  const selectedTabSection = ReportingM.getSelectedTabSection(chartTabName, tabSections)

  return (
    <Layout>
      <div className="space-y-4">
        <H2>Reports</H2>
        <div className="shadow-sm border rounded-md p-0.5 bg-gray-50">
          <Filters
            filters={filters}
            stagedFilters={stagedFilters}
            fields={fields(dateRangeFieldId)}
            filtersApi={filtersApi}
            onApply={() => setFilters(stagedFilters)}
          />
          <p className="text-sm text-gray-500 -mt-2 px-6 pb-3 sm:-mt-8">
            {showComparison && comparisonDateRange
              ? `compared to ${TimeM.toDateRangeString(
                  comparisonDateRange.from,
                  comparisonDateRange.to,
                  TimeM.formatDateTime
                )}`
              : 'no comparison'}
          </p>
        </div>
        <>
          <TabSections
            tabSections={tabSections}
            selectedTabName={chartTabName || undefined}
            onTabSelect={setChartTabName}
          />
          {selectedTabSection?.name === ReportingM.Suppliers.tabSectionNameRFQs ? (
            // RFQs Section
            rfqReportingDataError || comparisonRfqError ? (
              <p className="text-gray-700 text-sm">
                {(rfqReportingDataError || comparisonRfqError)?.message}
              </p>
            ) : !rfqReportingData || !comparisonRfqData ? (
              <Spinner />
            ) : (
              <ReportingSectionRFQs
                chartData={rfqReportingData.chartData}
                comparisonChartData={comparisonRfqData.chartData}
                dateIntervalDays={dateIntervalDays}
                selectedChartTab={selectedRfqChartTab}
                showComparison={showComparison}
                comparisonDateRange={comparisonDateRange}
                cardsChildren={
                  <>
                    <Card title="Dealers Breakdown">
                      <Card.Section>
                        <TableWrapper>
                          <ReportingTableStoreBreakdownRFQ
                            storesBreakdown={rfqReportingData.storesBreakdown}
                            comparisonStoresBreakdown={
                              showComparison ? comparisonRfqData.storesBreakdown : undefined
                            }
                          />
                        </TableWrapper>
                      </Card.Section>
                    </Card>
                    <Card title="RFQ Owners Breakdown">
                      <Card.Section>
                        <TableWrapper>
                          <ReportingTableOwnerBreakdownRFQ
                            ownersBreakdown={rfqReportingData.ownersBreakdown}
                            comparisonOwnersBreakdown={
                              showComparison ? comparisonRfqData.ownersBreakdown : undefined
                            }
                          />
                        </TableWrapper>
                      </Card.Section>
                    </Card>
                  </>
                }
                dataChildren={
                  <ReportingTableRFQ
                    allRfqs={rfqReportingData.allRfqs}
                    showDealer
                    linkPrefix={suppliersUrl}
                  />
                }
                comparisonDataChildren={
                  <ReportingTableRFQ
                    allRfqs={comparisonRfqData.allRfqs}
                    showDealer
                    linkPrefix={suppliersUrl}
                  />
                }
              />
            )
          ) : selectedTabSection?.name === ReportingM.Suppliers.tabSectionNameOrders ? (
            // Orders Section
            orderReportingDataError || comparisonOrderReportingDataError ? (
              <p className="text-gray-700 text-sm">
                {(orderReportingDataError || comparisonOrderReportingDataError)?.message}
              </p>
            ) : !orderReportingData || !comparisonOrderReportingData ? (
              <Spinner />
            ) : (
              <ReportingSectionOrders
                chartData={orderReportingData.chartData}
                comparisonChartData={comparisonOrderReportingData.chartData}
                selectedChartTab={selectedOrderChartTab}
                dateIntervalDays={dateIntervalDays}
                showComparison={showComparison}
                comparisonDateRange={comparisonDateRange}
                dataChildren={
                  <ReportingTableOrder
                    allStoreOrders={orderReportingData.allStoreOrders}
                    linkPrefix={suppliersUrl}
                    showDealer
                  />
                }
                comparisonDataChildren={
                  <ReportingTableOrder
                    allStoreOrders={comparisonOrderReportingData.allStoreOrders}
                    linkPrefix={suppliersUrl}
                    showDealer
                  />
                }
              />
            )
          ) : (
            <Card>
              <Card.Section>
                <p className="text-sm text-gray-700">
                  Select a report from the list above to see more details.
                </p>
              </Card.Section>
            </Card>
          )}
        </>
      </div>
    </Layout>
  )
}

export default Reports
