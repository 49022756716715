import React from 'react'

import useImportProducts from '../../hooks/useImportProducts'
import useMsgr from '../../hooks/useMsgr'
import useSession from '../../hooks/useSession'

import Action from '@/gf/components/Action'
import Form from '@/gf/components/Form'
import H2 from '../H2'
import Layout from '../Layout'

const Import = () => {
  const { user } = useSession()
  const msgr = useMsgr()
  const [file, setFile] = React.useState<File>()
  const [errors, setErrors] = React.useState<{ message: string }[]>()
  const importProducts = useImportProducts()

  const onSubmit = () => {
    if (!file) return

    setErrors([])
    msgr.clear()

    importProducts({ file, organizationId: user.organization.id })
      .then(([status, errs]) => {
        if (status === 'error') {
          setErrors(errs)
        } else {
          msgr.add('File imported.', 'positive')
          setFile(undefined)
        }
      })
      .catch(() => msgr.addUnknownError())
  }

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files || []
    setFile(files[0])
  }

  return (
    <Layout>
      <div className="space-y-4">
        <H2>Import Products</H2>
        <Form onSubmit={onSubmit}>
          <div className="space-y-4">
            <input onChange={onChange} type="file" />
            <Action.S type="submit" disabled={!file}>
              Import
            </Action.S>
          </div>
        </Form>
        {errors?.map((error) => <div>{error.message}</div>)}
      </div>
    </Layout>
  )
}

export default Import
