import { gql, useQuery } from '@apollo/client'

import { Brand } from '../../types'

interface Data {
  brands: Brand[]
}

const query = gql`
  query FetchBrands {
    brands {
      id
      name
    }
  }
`

const useBrands = () => useQuery<Data>(query, { fetchPolicy: 'no-cache' })

export default useBrands
