import rollbar from '@/gf/config/rollbar'
import ConfigContext from '../contexts/Config'

const devScheme = process.env.DEV_SSL ? 'https' : 'http'

const gfBaseUrl = process.env.CANONICAL_HOST
  ? `https://${process.env.CANONICAL_HOST}`
  : `${devScheme}://local.gearflow.com:4000`

const suppliersUrl = process.env.CANONICAL_HOST
  ? `https://suppliers.${process.env.CANONICAL_HOST}`
  : `${devScheme}://suppliers.local.gearflow.com:4000`

const gqlBaseUrl = process.env.CANONICAL_HOST
  ? `https://oems.${process.env.CANONICAL_HOST}`
  : `${devScheme}://oems.local.gearflow.com:4000`

const config = { gfBaseUrl, suppliersUrl, gqlBaseUrl, rollbar }

const ConfigProvider: React.FC<{ children?: React.ReactNode }> = ({ children }) => (
  <ConfigContext.Provider value={config}>{children}</ConfigContext.Provider>
)

export default ConfigProvider
