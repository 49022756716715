import { ReportingOwnerResult } from '../../types'

import Comparison from './Comparison'
import { Table, Tbody, Td, Thead, Tr } from './Table'
import Tooltip from './Tooltip'

const cols = 5

const ReportingTableOwnerBreakdownRFQ = ({
  ownersBreakdown,
  comparisonOwnersBreakdown,
}: {
  ownersBreakdown: ReportingOwnerResult[]
  comparisonOwnersBreakdown?: ReportingOwnerResult[]
}) => (
  <Table>
    <Thead>
      <Tr>
        <Td>
          RFQ Owner
          <Tooltip
            className="inline-block align-bottom ml-1"
            size="medium"
            text="In the 'RFQ Details' page, set the 'Owner' of the RFQ to track who is responsible for quoting it."
          />
        </Td>
        <Td>Average Time to Quote (hours)</Td>
        <Td>Average Time to Quote Change</Td>
        <Td>RFQs Quoted</Td>
        <Td>RFQs Quoted Change</Td>
      </Tr>
    </Thead>
    <Tbody>
      {ownersBreakdown.length === 0 ? (
        <Tr>
          <Td colSpan={cols}>No results found.</Td>
        </Tr>
      ) : (
        ownersBreakdown.map((ownerResult) => {
          const comparisonOwnerResult = comparisonOwnersBreakdown?.find(
            (comparison) => comparison.owner?.id === ownerResult.owner?.id
          )
          return (
            <Tr key={ownerResult.owner?.id ?? 'None'}>
              <Td>{ownerResult.owner?.name ?? 'None'}</Td>
              <Td>{ownerResult.averageTimeToQuote?.toFixed(2) ?? 'N/A'}</Td>
              <Td>
                {comparisonOwnerResult &&
                ownerResult.averageTimeToQuote &&
                comparisonOwnerResult.averageTimeToQuote ? (
                  <Comparison
                    value={ownerResult.averageTimeToQuote}
                    comparisonValue={comparisonOwnerResult.averageTimeToQuote}
                    downIsGood
                  />
                ) : (
                  'N/A'
                )}
              </Td>
              <Td>{ownerResult.rfqsQuotedCount}</Td>
              <Td>
                {comparisonOwnerResult ? (
                  <Comparison
                    value={ownerResult.rfqsQuotedCount}
                    comparisonValue={comparisonOwnerResult.rfqsQuotedCount}
                    displayType="value"
                    toFixed={0}
                  />
                ) : (
                  'N/A'
                )}
              </Td>
            </Tr>
          )
        })
      )}
    </Tbody>
  </Table>
)

export default ReportingTableOwnerBreakdownRFQ
