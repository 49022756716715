import { DateTime } from 'luxon'
import { Filter, ReportingTab, ReportingTabSection, ReportingTabSectionTab } from '../../types'

const getSelectedChartTab = <T extends { name: string }>(
  chartTabName: string | undefined,
  tabs: T[]
) => tabs.find((tab) => tab.name === chartTabName) ?? tabs[0]

const getSelectedTabSection = (
  selectedChartTabName: string | undefined | null,
  tabSections: ReportingTabSection[]
) =>
  tabSections.find((tabSection) =>
    tabSection.tabs.reduce(
      (acc: boolean, tab: ReportingTabSectionTab) => tab.name === selectedChartTabName || acc,
      false
    )
  )

// Return the comparison date range for the given filters
const getComparisonDateRange = (filters: Filter[], dateRangeFieldId: string) =>
  filters.reduce((acc: { from: string; to: string } | undefined, filter: Filter) => {
    if (acc ?? (filter.typeId !== 'date_time_in_range' || filter.fieldId !== dateRangeFieldId))
      return acc
    if (!filter) return undefined
    // If there's no from date, then there's no comparison, because we default to the oldest time possible
    if (!filter.from) return undefined
    const fromDateTime = DateTime.fromISO(filter.from) as DateTime<true>
    const toDateTime = filter.to ? (DateTime.fromISO(filter.to) as DateTime<true>) : DateTime.now()
    const dateRangeDuration = toDateTime.diff(fromDateTime)
    return {
      from: fromDateTime.minus(dateRangeDuration).toISO(),
      to: toDateTime.minus(dateRangeDuration).minus({ seconds: 1 }).toISO(),
    }
  }, undefined)

const getComparisonFilters = (
  filters: Filter[],
  compDateRange: { from: string; to: string } | undefined,
  dateRangeFieldId: string
) =>
  !compDateRange
    ? filters
    : filters.map((filter) =>
        filter.typeId === 'date_time_in_range' && filter.fieldId === dateRangeFieldId
          ? { ...filter, from: compDateRange.from, to: compDateRange.to }
          : filter
      )

// Suppliers -----------------------------------------------------------------------------------------------------------

const tabSectionNameRFQs = 'RFQs'
const tabSectionNameOrders = 'Orders'

const tabAverageTimeToQuote: ReportingTab = {
  name: 'Average Time to Quote',
  chartDatumKey: 'averageTimeToQuote',
  comparisonMarkerLegend: 'previous avg. time to quote',
  yLegend: 'Average Time To Quote (hours)',
  xLegend: 'RFQ Created At Date',
}
const tabRfqsQuoted: ReportingTab = {
  name: 'RFQs Quoted',
  chartDatumKey: 'rfqsQuotedCount',
  comparisonMarkerLegend: 'previous total RFQs quoted',
  yLegend: 'Total RFQs Quoted',
  xLegend: 'RFQ Created At Date',
}
const tabGrossPartSales: ReportingTab = {
  name: 'Gross Part Sales',
  chartDatumKey: 'total',
  comparisonMarkerLegend: 'previous total gross part sales',
  yLegend: 'Total Gross Part Sales',
  yFormat: '$,.0f',
  yDataFormat: '$,.2f',
  xLegend: 'Order Created At Date',
}
const tabNetPartSales: ReportingTab = {
  name: 'Net Part Sales',
  chartDatumKey: 'netTotal',
  comparisonMarkerLegend: 'previous total net part sales',
  yLegend: 'Total Net Part Sales',
  yFormat: '$,.0f',
  yDataFormat: '$,.2f',
  xLegend: 'Order Created At Date',
}

const Suppliers = {
  tabSectionNameRFQs,
  tabSectionNameOrders,
  tabAverageTimeToQuote,
  tabRfqsQuoted,
  tabGrossPartSales,
  tabNetPartSales,
}

export default {
  getSelectedChartTab,
  getSelectedTabSection,
  getComparisonDateRange,
  getComparisonFilters,
  Suppliers,
}
