import Money from '@/gf/modules/Money'

import { AddProductForm, AddProductFormPayload } from '../../types'

const init = ({ organizationId }: { organizationId: string }): AddProductForm => ({
  name: '',
  mpn: '',
  altMpn: '',
  sku: '',
  shortDescription: '',
  longDescription: '',
  availability: '',
  leadTime: '',
  isOem: false,
  listPrice: null,
  shippingCost: null,
  brandId: null,
  categoryId: null,
  organizationId,
})

const toPayload = (form: AddProductForm): AddProductFormPayload => {
  const listPrice = form.listPrice && Money.toPayload(form.listPrice)
  const shippingCost = form.shippingCost && Money.toPayload(form.shippingCost)

  return { ...form, listPrice, shippingCost }
}

export default { init, toPayload }
