import { gql, useQuery } from '@apollo/client'
import { jsonToGraphQLQuery, VariableType } from 'json-to-graphql-query'

import { OrderReportingMetricsPayload } from '@/types'

import { orderReportingMetrics } from '@/gf/queries/commonGqlTypes'

import MoneyContext from '@/gf/modules/Money'
import StoreOrderM from '@/gf/modules/StoreOrder'
import RequestForQuoteContext from '@/gf/modules/RequestForQuote'

const doc = gql(
  jsonToGraphQLQuery(
    {
      query: {
        __variables: {
          organizationId: 'String!',
          filters: '[[String]]!',
          timezone: 'String!',
        },
        orderReportingMetrics: {
          __args: {
            organizationId: new VariableType('organizationId'),
            filters: new VariableType('filters'),
            timezone: new VariableType('timezone'),
          },
          ...orderReportingMetrics,
          chartData: {
            ...orderReportingMetrics,
            date: true,
          },
          allStoreOrders: {
            ...orderReportingMetrics,
            storeOrder: {
              id: true,
              shortId: true,
              insertedAt: true,
              state: true,
              step: true,
              deliveryMethod: true,
              paymentStatus: true,
              store: {
                name: true,
              },
            },
            requestForQuote: {
              shortId: true,
              id: true,
              insertedAt: true,
              owners: {
                id: true,
                name: true,
              },
            },
          },
        },
      },
    },
    { pretty: true }
  )
)

interface ReportingMetricsData {
  orderReportingMetrics: OrderReportingMetricsPayload
}
interface ReportingMetricsVars {
  organizationId: string
  filters: string[][]
  timezone: string
}

interface Props extends Omit<ReportingMetricsVars, 'filters'> {
  filters?: ReportingMetricsVars['filters']
}
const useOrderReportingMetrics = ({ organizationId, filters = [], timezone }: Props) => {
  const { loading, error, data } = useQuery<ReportingMetricsData, ReportingMetricsVars>(doc, {
    variables: { organizationId, filters, timezone },
    fetchPolicy: 'no-cache',
  })

  const reportingMetrics = data && {
    ...data.orderReportingMetrics,
    total: MoneyContext.fromPayload(data.orderReportingMetrics.total),
    netTotal: MoneyContext.fromPayload(data.orderReportingMetrics.netTotal),
    chartData: data.orderReportingMetrics.chartData.map((chartDatumPayload) => ({
      ...chartDatumPayload,
      total: chartDatumPayload.total ? MoneyContext.fromPayload(chartDatumPayload.total) : null,
      netTotal: chartDatumPayload.netTotal
        ? MoneyContext.fromPayload(chartDatumPayload.netTotal)
        : null,
    })),
    allStoreOrders: data.orderReportingMetrics.allStoreOrders.map(
      (reportingOrderResultPayload) => ({
        ...reportingOrderResultPayload,
        total: MoneyContext.fromPayload(reportingOrderResultPayload.total),
        netTotal: MoneyContext.fromPayload(reportingOrderResultPayload.netTotal),
        storeOrder: StoreOrderM.fromPayload(reportingOrderResultPayload.storeOrder),
        requestForQuote: reportingOrderResultPayload.requestForQuote
          ? RequestForQuoteContext.fromPayload(reportingOrderResultPayload.requestForQuote)
          : undefined,
      })
    ),
  }

  return { loading, error, data: reportingMetrics }
}

useOrderReportingMetrics.Query = doc
export default useOrderReportingMetrics
