import { ReactNode } from 'react'
import { ReportingTab, RfqReportingChartDatum } from '../../types'

import Card from './Card'
import ReportingResponsiveLineChart from './ReportingResponsiveLineChart'
import ReportingComparisonDataCard from './ReportingComparisonDataCard'

const ReportingSectionRFQs = ({
  chartData,
  comparisonChartData,
  selectedChartTab,
  showComparison,
  dateIntervalDays,
  comparisonDateRange,
  cardsChildren = null,
  dataChildren,
  comparisonDataChildren,
}: {
  chartData: RfqReportingChartDatum[]
  comparisonChartData: RfqReportingChartDatum[]
  selectedChartTab: ReportingTab
  showComparison: boolean
  dateIntervalDays?: number
  comparisonDateRange: { from: string; to: string } | undefined
  cardsChildren?: ReactNode
  dataChildren: ReactNode
  comparisonDataChildren: ReactNode
}) => (
  <>
    <Card>
      <Card.Section>
        <div className="flex flex-grow justify-center items-center h-72">
          <ReportingResponsiveLineChart
            data={chartData}
            comparisonData={comparisonChartData}
            selectedChartTab={selectedChartTab}
            showComparison={showComparison}
            dateIntervalDays={dateIntervalDays}
          />
        </div>
      </Card.Section>
    </Card>
    {cardsChildren}
    <ReportingComparisonDataCard
      dataChildren={dataChildren}
      comparisonDataChildren={comparisonDataChildren}
      comparisonDateRange={comparisonDateRange}
    />
  </>
)

export default ReportingSectionRFQs
