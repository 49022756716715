import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  Date: { input: string; output: string; }
  Filter: { input: string; output: string; }
  NaiveDateTime: { input: string; output: string; }
  Upload: { input: unknown; output: unknown; }
};

export type Brand = {
  __typename?: 'Brand';
  id: Scalars['ID']['output'];
  logoUrl: Maybe<Scalars['String']['output']>;
  lookupKeys: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  name: Scalars['String']['output'];
  order: Maybe<Scalars['Int']['output']>;
  parentId: Maybe<Scalars['ID']['output']>;
  slug: Maybe<Scalars['String']['output']>;
};

export type Category = {
  __typename?: 'Category';
  ascendants: Array<Category>;
  children: Array<Category>;
  id: Scalars['String']['output'];
  imageUrl: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  parentId: Maybe<Scalars['String']['output']>;
  slug: Maybe<Scalars['String']['output']>;
  slugPath: Maybe<Scalars['String']['output']>;
};

export type Invoice = {
  __typename?: 'Invoice';
  balanceInvoiceId: Maybe<Scalars['String']['output']>;
  balanceTransactionId: Maybe<Scalars['String']['output']>;
  dueDate: Maybe<Scalars['NaiveDateTime']['output']>;
  id: Maybe<Scalars['String']['output']>;
  isCanceled: Maybe<Scalars['Boolean']['output']>;
  isFinanced: Maybe<Scalars['Boolean']['output']>;
  isPaid: Maybe<Scalars['Boolean']['output']>;
  link: Maybe<Scalars['String']['output']>;
  totalPrice: Maybe<Money>;
};

export type Money = {
  __typename?: 'Money';
  amount: Maybe<Scalars['Int']['output']>;
  currency: Maybe<Scalars['String']['output']>;
};

export type MoneyInput = {
  amount: InputMaybe<Scalars['Int']['input']>;
  currency: InputMaybe<Scalars['String']['input']>;
};

export type OrderReportingChartDatum = {
  __typename?: 'OrderReportingChartDatum';
  date: Maybe<Scalars['Date']['output']>;
  netTotal: Maybe<Money>;
  total: Maybe<Money>;
};

export type OrderReportingDatum = {
  __typename?: 'OrderReportingDatum';
  netTotal: Maybe<Money>;
  requestForQuote: Maybe<RequestForQuote>;
  storeOrder: Maybe<StoreOrder>;
  total: Maybe<Money>;
};

export type OrderReportingMetrics = {
  __typename?: 'OrderReportingMetrics';
  allStoreOrders: Maybe<Array<Maybe<OrderReportingDatum>>>;
  chartData: Maybe<Array<Maybe<OrderReportingChartDatum>>>;
  netTotal: Maybe<Money>;
  total: Maybe<Money>;
};

export type Organization = {
  __typename?: 'Organization';
  convictionalId: Maybe<Scalars['String']['output']>;
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  storeLinks: Array<StoreLink>;
};

export type PaginatedProducts = {
  __typename?: 'PaginatedProducts';
  pagination: Maybe<Pagination>;
  products: Maybe<Array<Maybe<Product>>>;
};

export type Pagination = {
  __typename?: 'Pagination';
  totalPages: Maybe<Scalars['Int']['output']>;
  totalResults: Maybe<Scalars['Int']['output']>;
};

export type Product = {
  __typename?: 'Product';
  altMpn: Maybe<Scalars['String']['output']>;
  availability: Maybe<Scalars['String']['output']>;
  brand: Maybe<Brand>;
  category: Maybe<Category>;
  id: Maybe<Scalars['ID']['output']>;
  images: Maybe<Array<Maybe<ProductImage>>>;
  isOem: Maybe<Scalars['Boolean']['output']>;
  leadTime: Maybe<Scalars['String']['output']>;
  leadTimeDate: Maybe<Scalars['NaiveDateTime']['output']>;
  listPrice: Maybe<Money>;
  longDescription: Maybe<Scalars['String']['output']>;
  mpn: Maybe<Scalars['String']['output']>;
  name: Maybe<Scalars['String']['output']>;
  shippingCost: Maybe<Money>;
  shortDescription: Maybe<Scalars['String']['output']>;
  sku: Maybe<Scalars['String']['output']>;
};

export type ProductImage = {
  __typename?: 'ProductImage';
  variants: Maybe<Array<Maybe<ProductImageVariant>>>;
};

export type ProductImageVariant = {
  __typename?: 'ProductImageVariant';
  name: Maybe<Scalars['String']['output']>;
  url: Maybe<Scalars['String']['output']>;
};

export type RequestForQuote = {
  __typename?: 'RequestForQuote';
  closed: Maybe<Scalars['Boolean']['output']>;
  emailAddress: Maybe<Scalars['String']['output']>;
  enabled: Maybe<Scalars['Boolean']['output']>;
  fullName: Maybe<Scalars['String']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  machineInformation: Maybe<Scalars['String']['output']>;
  owners: Maybe<Array<Maybe<User>>>;
  partsRequest: Maybe<Scalars['String']['output']>;
  phoneNumber: Maybe<Scalars['String']['output']>;
  shortId: Maybe<Scalars['String']['output']>;
  step: RequestForQuoteStep;
  storeOrders: Maybe<Array<Maybe<StoreOrder>>>;
  userId: Maybe<Scalars['String']['output']>;
  zipCode: Maybe<Scalars['String']['output']>;
};

export enum RequestForQuoteStep {
  Canceled = 'CANCELED',
  Fulfilled = 'FULFILLED',
  Fulfilling = 'FULFILLING',
  Inbound = 'INBOUND',
  PoReceived = 'PO_RECEIVED',
  Quoted = 'QUOTED'
}

export type RfqReportingChartDatum = {
  __typename?: 'RfqReportingChartDatum';
  averageTimeToQuote: Maybe<Scalars['Float']['output']>;
  date: Maybe<Scalars['Date']['output']>;
  rfqsQuotedCount: Maybe<Scalars['Int']['output']>;
};

export type RfqReportingDatum = {
  __typename?: 'RfqReportingDatum';
  requestForQuote: Maybe<RequestForQuote>;
  storeOrder: Maybe<StoreOrder>;
  timeToQuote: Maybe<Scalars['Float']['output']>;
};

export type RfqReportingMetrics = {
  __typename?: 'RfqReportingMetrics';
  allRfqs: Maybe<Array<Maybe<RfqReportingDatum>>>;
  averageTimeToQuote: Maybe<Scalars['Float']['output']>;
  chartData: Maybe<Array<Maybe<RfqReportingChartDatum>>>;
  ownersBreakdown: Maybe<Array<Maybe<RfqReportingOwnerResult>>>;
  rfqsQuotedCount: Maybe<Scalars['Int']['output']>;
  storesBreakdown: Maybe<Array<Maybe<RfqReportingStoreResult>>>;
};

export type RfqReportingOwnerResult = {
  __typename?: 'RfqReportingOwnerResult';
  averageTimeToQuote: Maybe<Scalars['Float']['output']>;
  owner: Maybe<User>;
  rfqsQuotedCount: Maybe<Scalars['Int']['output']>;
};

export type RfqReportingStoreResult = {
  __typename?: 'RfqReportingStoreResult';
  averageTimeToQuote: Maybe<Scalars['Float']['output']>;
  rfqsQuotedCount: Maybe<Scalars['Int']['output']>;
  store: Maybe<Store>;
};

export type RootMutationType = {
  __typename?: 'RootMutationType';
  addProduct: Maybe<Scalars['ID']['output']>;
  createPresignedS3Url: Maybe<Scalars['String']['output']>;
  importProducts: Maybe<Scalars['Boolean']['output']>;
  inviteDealer: Maybe<Scalars['ID']['output']>;
  updateProduct: Maybe<Scalars['Boolean']['output']>;
};


export type RootMutationTypeAddProductArgs = {
  altMpn: Scalars['String']['input'];
  availability: Scalars['String']['input'];
  brandId: InputMaybe<Scalars['ID']['input']>;
  categoryId: InputMaybe<Scalars['ID']['input']>;
  isOem: Scalars['Boolean']['input'];
  leadTime: Scalars['String']['input'];
  listPrice: InputMaybe<MoneyInput>;
  longDescription: Scalars['String']['input'];
  mpn: Scalars['String']['input'];
  name: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
  shippingCost: InputMaybe<MoneyInput>;
  shortDescription: Scalars['String']['input'];
  sku: Scalars['String']['input'];
};


export type RootMutationTypeCreatePresignedS3UrlArgs = {
  key: Scalars['String']['input'];
};


export type RootMutationTypeImportProductsArgs = {
  file: Scalars['Upload']['input'];
  organizationId: Scalars['ID']['input'];
};


export type RootMutationTypeInviteDealerArgs = {
  email: Scalars['String']['input'];
  organizationId: Scalars['ID']['input'];
};


export type RootMutationTypeUpdateProductArgs = {
  altMpn: Scalars['String']['input'];
  availability: Scalars['String']['input'];
  brandId: InputMaybe<Scalars['ID']['input']>;
  categoryId: InputMaybe<Scalars['ID']['input']>;
  id: Scalars['ID']['input'];
  imageUrl: InputMaybe<Scalars['String']['input']>;
  isOem: Scalars['Boolean']['input'];
  leadTime: Scalars['String']['input'];
  leadTimeDate: Scalars['String']['input'];
  listPrice: InputMaybe<MoneyInput>;
  longDescription: Scalars['String']['input'];
  mpn: Scalars['String']['input'];
  name: Scalars['String']['input'];
  shippingCost: InputMaybe<MoneyInput>;
  shortDescription: Scalars['String']['input'];
  sku: Scalars['String']['input'];
};

export type RootQueryType = {
  __typename?: 'RootQueryType';
  brands: Maybe<Array<Maybe<Brand>>>;
  categories: Maybe<Array<Maybe<Category>>>;
  dealers: Maybe<Array<Maybe<Store>>>;
  /** Fetch Order reporting metrics for the logged in OEM */
  orderReportingMetrics: Maybe<OrderReportingMetrics>;
  paginatedProducts: Maybe<PaginatedProducts>;
  /** Fetch RFQ reporting metrics for the logged in OEM */
  rfqReportingMetrics: Maybe<RfqReportingMetrics>;
  session: Session;
};


export type RootQueryTypeDealersArgs = {
  organizationId: Scalars['ID']['input'];
};


export type RootQueryTypeOrderReportingMetricsArgs = {
  filters: Array<InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>>;
  organizationId: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};


export type RootQueryTypePaginatedProductsArgs = {
  filter: InputMaybe<Scalars['Filter']['input']>;
  organizationId: Scalars['ID']['input'];
  page: InputMaybe<Scalars['Int']['input']>;
};


export type RootQueryTypeRfqReportingMetricsArgs = {
  filters: Array<InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>>;
  organizationId: Scalars['String']['input'];
  timezone: Scalars['String']['input'];
};

export type Session = {
  __typename?: 'Session';
  user: Maybe<SessionUser>;
};

export type SessionUser = {
  __typename?: 'SessionUser';
  email: Scalars['String']['output'];
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
  organization: Organization;
};

export type Store = {
  __typename?: 'Store';
  id: Scalars['ID']['output'];
  name: Scalars['String']['output'];
};

export type StoreLink = {
  __typename?: 'StoreLink';
  primary: Scalars['Boolean']['output'];
  store: Store;
};

export type StoreOrder = {
  __typename?: 'StoreOrder';
  deliveryMethod: Maybe<Scalars['String']['output']>;
  gearflowShipping: Maybe<Scalars['Boolean']['output']>;
  id: Maybe<Scalars['ID']['output']>;
  insertedAt: Maybe<Scalars['NaiveDateTime']['output']>;
  invoices: Maybe<Array<Maybe<Invoice>>>;
  payOnInvoice: Maybe<Scalars['Boolean']['output']>;
  purchaseOrder: Maybe<Scalars['String']['output']>;
  shippingAmount: Maybe<Money>;
  shortId: Maybe<Scalars['String']['output']>;
  state: StoreOrderState;
  store: Maybe<Store>;
  timingDetails: Maybe<Scalars['String']['output']>;
  updatedAt: Maybe<Scalars['NaiveDateTime']['output']>;
};

export enum StoreOrderState {
  BuyerReceived = 'BUYER_RECEIVED',
  Cancelled = 'CANCELLED',
  OpenQuote = 'OPEN_QUOTE',
  PartiallyShipped = 'PARTIALLY_SHIPPED',
  Processing = 'PROCESSING',
  QuoteDenied = 'QUOTE_DENIED',
  Readonly = 'READONLY',
  ReadyForPickup = 'READY_FOR_PICKUP',
  Received = 'RECEIVED',
  Refunded = 'REFUNDED',
  Shipped = 'SHIPPED'
}

export type User = {
  __typename?: 'User';
  id: Maybe<Scalars['ID']['output']>;
  name: Maybe<Scalars['String']['output']>;
};

export type FetchSessionQueryVariables = Exact<{ [key: string]: never; }>;


export type FetchSessionQuery = { __typename?: 'RootQueryType', session: { __typename?: 'Session', user: { __typename?: 'SessionUser', id: string, name: string, email: string, organization: { __typename?: 'Organization', id: string, name: string, convictionalId: string | null, storeLinks: Array<{ __typename?: 'StoreLink', primary: boolean, store: { __typename?: 'Store', id: string, name: string } }> } } | null } };


export const FetchSessionDocument = gql`
    query FetchSession {
  session {
    user {
      id
      name
      email
      organization {
        id
        name
        convictionalId
        storeLinks {
          primary
          store {
            id
            name
          }
        }
      }
    }
  }
}
    `;

/**
 * __useFetchSessionQuery__
 *
 * To run a query within a React component, call `useFetchSessionQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchSessionQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchSessionQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchSessionQuery(baseOptions?: Apollo.QueryHookOptions<FetchSessionQuery, FetchSessionQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchSessionQuery, FetchSessionQueryVariables>(FetchSessionDocument, options);
      }
export function useFetchSessionLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchSessionQuery, FetchSessionQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchSessionQuery, FetchSessionQueryVariables>(FetchSessionDocument, options);
        }
export function useFetchSessionSuspenseQuery(baseOptions?: Apollo.SkipToken | Apollo.SuspenseQueryHookOptions<FetchSessionQuery, FetchSessionQueryVariables>) {
          const options = baseOptions === Apollo.skipToken ? baseOptions : {...defaultOptions, ...baseOptions}
          return Apollo.useSuspenseQuery<FetchSessionQuery, FetchSessionQueryVariables>(FetchSessionDocument, options);
        }
export type FetchSessionQueryHookResult = ReturnType<typeof useFetchSessionQuery>;
export type FetchSessionLazyQueryHookResult = ReturnType<typeof useFetchSessionLazyQuery>;
export type FetchSessionSuspenseQueryHookResult = ReturnType<typeof useFetchSessionSuspenseQuery>;
export type FetchSessionQueryResult = Apollo.QueryResult<FetchSessionQuery, FetchSessionQueryVariables>;
export function refetchFetchSessionQuery(variables?: FetchSessionQueryVariables) {
      return { query: FetchSessionDocument, variables: variables }
    }