/**
 * Select from 2d grid of tab cards
 * This is featured on the Reports pages
 */

import { useState } from 'react'
import classNames from 'classnames'
import { ChevronUpIcon } from '@heroicons/react/solid'

import { ReportingTabSection } from '../../types'

const TabSections = ({
  tabSections,
  selectedTabName,
  onTabSelect,
}: {
  tabSections: ReportingTabSection[]
  selectedTabName?: string
  onTabSelect?: (selectedTabName: string) => void
}) => {
  const [showAllTabs, setShowAllTabs] = useState<boolean>(!selectedTabName)
  const isTabSectionSelected = (tabSection: ReportingTabSection) =>
    tabSection.tabs.reduce((acc, tab) => tab.name === selectedTabName || acc, false)
  return (
    <div className="mt-5 p-4 rounded-md border border-gray-200 bg-gray-50 space-y-4">
      {tabSections
        .sort((tabSectionA, tabSectionB) =>
          isTabSectionSelected(tabSectionA) ? -1 : isTabSectionSelected(tabSectionB) ? 1 : 0
        )
        .map(
          (tabSection) =>
            (showAllTabs || isTabSectionSelected(tabSection)) && (
              <div key={tabSection.name}>
                <div className="flex justify-start ml-4 mb-1 text-base font-medium text-gray-400">
                  {tabSection.name}
                </div>
                <dl className="grid grid-cols-1 gap-5 sm:grid-cols-3 p-2 rounded-md bg-gray-100">
                  {tabSection.tabs.map((tab) => (
                    <button
                      key={tab.name}
                      className={classNames(
                        'text-left rounded-md hover:bg-gray-200 -m-1 p-1 focus:outline-none',
                        {
                          'bg-gray-200 ring-2 ring-gray-300': tab.name === selectedTabName,
                        }
                      )}
                      type="button"
                      onClick={() => {
                        setShowAllTabs(false)
                        if (onTabSelect) onTabSelect(tab.name)
                      }}
                    >
                      {tab.children}
                    </button>
                  ))}
                </dl>
              </div>
            )
        )}
      {selectedTabName && (
        <div className="flex justify-end -mt-2">
          <button
            className="inline-flex items-center mx-3 text-sm text-gray-500 rounded hover:text-gray-700"
            type="button"
            onClick={() => setShowAllTabs(!showAllTabs)}
          >
            <span>{showAllTabs ? 'Show Less' : 'Show More'}</span>
            <ChevronUpIcon
              className={classNames('inline-block w-6 h-6 ml-0.5 -mr-1', {
                'transform rotate-180': !showAllTabs,
              })}
            />
          </button>
        </div>
      )}
    </div>
  )
}

export default TabSections
